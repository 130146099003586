import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import React from "react";

const QRCodeGenerator = ({ user }) => {
  const env_type = process.env.REACT_APP_ENV_TYPE;


  const baseURL =
    env_type == "local"
      ? "http://localhost:3000"
      : env_type === "prod"
      ? "https://emportal.me"
      : env_type === "dev"
      ? "https://dev.emportal.me"
      : "https://uat.emportal.me";

  const url = `${baseURL}/#/feedback-form/${user?.associateId}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    alert("URL copied to clipboard!");
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check this out!",
          url,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Sharing not supported on this browser.");
    }
  };

  return (
    <Paper
      elevation={4}
      sx={{
        padding: 3,
        borderRadius: 3,
        textAlign: "center",
        maxWidth: 400,
        margin: "auto",
      }}
    >
      <Typography variant="h6" color="primary" fontWeight="bold" gutterBottom>
        Scan QR to Provide Feedback
      </Typography>
      <QRCodeCanvas value={url} size={100} />
      <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
        Use your mobile camera or a QR scanner to open the feedback form.
      </Typography>
      <Box display="flex" justifyContent="center" gap={1}>
        <Tooltip title="Copy URL">
          <IconButton onClick={handleCopy} color="primary">
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Share URL">
          <IconButton onClick={handleShare} color="secondary">
            <ShareIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Paper>
  );
};

export default QRCodeGenerator;
