import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Rating,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { OldUserAppraisal } from "./OldRatingsPage";
import { TaskDialog } from "./TaskDialog";
import { AutoAwesome, Comment, Info, Sync } from "@mui/icons-material";
import { perfParameters } from "../constant/constant";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartTooltip,
  Title,
  Legend
);

const UserAppraisal = (props) => {
  const { user, pm, projects, isDisabled, onSuccess } = props;
  const role = user.roles[0];
  const [ratings, setRatings] = useState([]);
  const [commentforEachObjective, setCommentForEachObjective] = useState([]);
  const [appraisaldata, setAppraisalData] = useState({ comments: "" });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmittedForRange, setIsSubmittedForRange] = useState(false);
  const location = useLocation();
  const [menuanchorEl, setMenuAnchorEl] = useState(null);
  const [comments, setComments] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("success", "error");
  const [message, setMessage] = useState("");
  const [submitloading, setSubmitLoading] = useState(false);
  const [pastRatings, setPastRatings] = useState({});
  const [projectManager, setProjectManager] = useState({});
  const navigate = useNavigate();
  const [isPreviousRatingPresent, setIsPreviousRatingsPresent] =
    useState(false);
  const [previousRatings, setPreviousRatings] = useState([]);
  const [teamRatings, setTeamRatings] = useState({});
  const [companyRatings, setCompanyRatings] = useState({});
  const [UKScores, setUKScores] = useState({});
  const [ukScore, setUkScore] = useState(0);
  const astId = props?.astId ?? location?.state?.astId;
  const project = props?.project ?? location?.state?.project;
  const [startDate, setStartDate] = useState(
    location?.state?.startDate ?? props?.startDate
  );
  const [endDate, setEndDate] = useState(
    location?.state?.endDate ?? props?.endDate
  );
  const [projectManagers, setProjectManagers] = useState([]);
  const [selectedPM, setSelectedPM] = useState({});
  const [open, setOpen] = useState(false);
  const [validation, setValidation] = useState("");
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [saving, setSaving] = useState(false);
   const [expanded, setExpanded] = useState(null);
  const [innerExpanded, setInnerExpanded] = useState({});
  const [expandedPanels, setExpandedPanels] = useState({});
  
  const handleExpand = (panel) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [panel]: !prev[panel],
    }));
  };
  
  const handleExpandCollapse = () => {
    const totalPanels = groupedParameters[pageIndex].length;
    const expandedCount = Object.values(expandedPanels).filter(Boolean).length;
    const currentlyAllExpanded = expandedCount === totalPanels;
  
    const isAnyPanelManuallyExpanded = Object.values(expandedPanels).includes(true);
  
    if (currentlyAllExpanded || isAnyPanelManuallyExpanded) {
      setExpandedPanels({});  
    } else {
      const newExpanded = {};
      groupedParameters[pageIndex].forEach((perfObj) => {
        const key = `${perfObj.index}-${perfObj.subIndex}`;
        newExpanded[key] = true;
      });
      setExpandedPanels(newExpanded); 
    }
  };
  
  const handleInnerExpand = (panel) => {
    setInnerExpanded((prev) => ({
      ...prev,
      [panel]: !prev[panel],  
    }));
  };
  

  const getCurrentDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const [startDateAndTime, setStartDateAndTime] = useState(
    getCurrentDateTime()
  );
  //const tstProjects = project.includes(",") ? project.split(",") : [project];

  useEffect(() => {
    axiosInstance
      .get(`/user/company/ratings?endDate=${endDate}`)
      .then((res) => setCompanyRatings(res?.data))
      .catch((err) => console.log(err));
    setIsPreviousRatingsPresent(false);
    setPreviousRatings([]);
    if (astId) {
      axiosInstance
        .get(`/user/get/project/managers?astId=${astId}`)
        .then((res) => {
          if (role === "Project Manager" && user?.associateId === astId) {
            const filteredProjectManagers = res?.data.filter(
              (p) => p.associateId !== astId
            );
            setSelectedPM(filteredProjectManagers[0]);
            setProjectManagers(filteredProjectManagers);
          } else {
            if (role === "Project Manager" && user) {
              const pm = res?.data.filter(
                (p) => p.associateId === user?.associateId
              );
              setSelectedPM(pm[0]);
            } else {
              setSelectedPM(res?.data[0]);
            }
            setProjectManagers(res?.data);
          }

          // Update the dropdown options
          //setProjectManagers(filteredProjectManagers);
        })
        /* if (role === "Project Manager" && user) {
            const pm = res?.data.filter(
              (p) => p.associateId === user?.associateId
            );
            setSelectedPM(pm[0]);
          } else {
            setSelectedPM(res?.data[0]);
          }
          setProjectManagers(res?.data);
        })*/
        .catch((err) => console.error(err.response?.data || err.message));

      axiosInstance
        .put(`/user/get/past/ratings?endDate=${endDate}`, [astId])
        .then((res) => setPastRatings(res?.data))
        .catch((err) => console.log(err));
    }

    if (astId) {
      axiosInstance
        .put(`/user/ast/ratings?endDate=${endDate}`, [astId])
        .then((res) => setUKScores(res?.data))
        .catch((err) => console.log(err));
    }

    if (project) {
      axiosInstance
        .get(`/user/team/ratings?endDate=${endDate}&project=${project}`)
        .then((res) => setTeamRatings(res?.data))
        .catch((err) => console.log(err));
    }

    //const projects = project.split(",");

    // axiosInstance
    //   .put(`/user/getAssociateRatings?pm=${selectedPM?.associateId}`, {
    //     startDate: startDate,
    //     endDate: endDate,
    //     user: { associateId: astId },
    //     project: { title: projects?.[0] },
    //   })
    //   .then((res) => {
    //     if (
    //       res.data?.[0].startDate === startDate &&
    //       res.data[0]?.endDate === endDate
    //     ) {
    //       setAppraisalData(res.data?.[0] ?? {});
    //     } else {
    //       let Obj = res.data[0];
    //       Obj["projectManagerSubmission"] = false;
    //       Obj["userSideSubmission"] = false;
    //       setAppraisalData(Obj);
    //     }
    //     if (res.data?.[0]?.ratingsObj !== null) {
    //       setComments(res.data?.[0]?.comments);
    //       setRatings(JSON.parse(res.data?.[0]?.ratingsObj));
    //       setCommentForEachObjective(JSON.parse(res.data?.[0]?.commentObj));
    //       setIsSubmittedForRange(true);
    //     } else {
    //       setIsSubmittedForRange(false);
    //     }
    //     if (res?.data?.[1] && res.data?.[1]?.ratingsObj !== null) {
    //       setPreviousRatings(JSON.parse(res.data?.[1]?.ratingsObj));
    //       setIsPreviousRatingsPresent(true);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [startDate, endDate, submitted]);

  const saveToDatabase = async (data) => {
    setSaving(true);
    axiosInstance
      .patch("/user/auto/save", data)
      .then((res) => {
        console.log("Data saved successfully");
        setTimeout(() => {
          setSaving(false);
        }, [2000]);
      })
      .catch((err) => {
        console.error("Error saving data:", err);
        setTimeout(() => {
          setSaving(false);
        }, [2000]);
      });
  };

  const saveData = useCallback(async () => {
    const data = {
      startDateAndTime: startDateAndTime,
      endDateAndTime: getCurrentDateTime(),
      ratings: JSON.stringify(ratings),
      comments: comments,
      user: { associateId: astId },
      project:
        astId === user?.associateId
          ? {
              projectManager: {
                associateId: selectedPM?.associateId,
              },
            }
          : {
              id:
                role === "Reporting Manager"
                  ? props.projects?.id
                  : role === "User"
                  ? 26
                  : props.projects.find(
                      (data) => data.title === location.state.project
                    ).id,
              projectManager: {
                associateId: selectedPM?.associateId,
              },
            },
      startDate: startDate,
      endDate: endDate,
      ratingAvg: Math.floor(totalRatingSum * (20 / 100) * 100) / 100,
      commentObj: JSON.stringify(commentforEachObjective),
    };
    if (
      (Object.keys(ratings).length > 0 ||
        Object.keys(commentforEachObjective).length > 0) &&
      selectedPM?.associateId
    ) {
      console.log("Auto-saving ratings:", data);
      await saveToDatabase(data);
      console.log("Ratings saved!");
    }
  }, [ratings]);

  useEffect(() => {
    const delay = setTimeout(() => {
      saveData();
    }, 2000);

    return () => clearTimeout(delay);
  }, [ratings, saveData]);

  useEffect(() => {
    if (Object.keys(selectedPM).length > 0) {
      // console.log({
      //   url: `/user/getAssociateRatings?pm=${selectedPM?.associateId}`,
      //   body: {
      //     projectManagerId: selectedPM?.associateId,
      //     startDate,
      //     endDate,
      //     user: { associateId: astId },
      //     project: { title: selectedPM?.projects },
      //   },
      // });

      axiosInstance
        .put(`/user/getAssociateRatings?pm=${selectedPM?.associateId}`, {
          projectManagerId:
            new Date(startDate).getFullYear() < 2025
              ? null
              : selectedPM?.associateId,
          startDate: startDate,
          endDate: endDate,
          user: { associateId: astId },
          project: { title: selectedPM?.projects },
        })
        .then((res) => {
          if (
            res.data?.[0].startDate === startDate &&
            res.data[0]?.endDate === endDate
          ) {
            setAppraisalData(res.data?.[0] ?? {});
          } else {
            let Obj = res.data[0];
            Obj["projectManagerSubmission"] = false;
            Obj["userSideSubmission"] = false;
            setAppraisalData(Obj);
            setRatings([]);
            setCommentForEachObjective([]);
            setComments("");
          }
          if (res.data?.[0]?.ratingsObj.length > 0) {
            setComments(res.data?.[0]?.comments);
            // setRatings(res?.data?.[0]?.ratingsList ?? []);
            setRatings(JSON.parse(res.data?.[0]?.ratingsObj));
            setUkScore(res.data?.[0]?.ukScore);
            setCommentForEachObjective(JSON.parse(res.data?.[0]?.commentObj));
            if (res?.data?.[0]?.userSideSubmission) {
              setIsSubmittedForRange(true);
            }
          } else {
            setRatings([]);
            setCommentForEachObjective([]);
            setComments("");
            setIsSubmittedForRange(false);
          }
          if (res?.data?.[1] && res.data?.[1]?.ratingsObj !== null) {
            setPreviousRatings(JSON.parse(res.data?.[1]?.ratingsObj));
            setIsPreviousRatingsPresent(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedPM]);

  const calculateAverageRatings = (teamRatings) => {
    const dateRatingsMap = {};
    Object.values(teamRatings).forEach(({ dates, ukScores }) => {
      dates.forEach((date, index) => {
        const range = `${moment(date.startDate).format("MMM D")} - ${moment(
          date.endDate
        ).format("MMM D")}`;
        if (!dateRatingsMap[range]) {
          dateRatingsMap[range] = [];
        }
        dateRatingsMap[range].push(ukScores[index]);
      });
    });

    const averages = Object.keys(dateRatingsMap).map((range) => {
      const ratings = dateRatingsMap[range];
      const average =
        ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length;
      return { range, average: Math.trunc(average) };
    });

    return averages;
  };
  const averageRatings = calculateAverageRatings(teamRatings);
  const averageCompanyRatings = calculateAverageRatings(companyRatings);

  const labels = UKScores?.[astId]?.dates?.map((date) => {
    const startDate = moment(date.startDate).format("MMM DD");
    const month = moment(date.startDate).format("MMM");
    const endDate = moment(date.endDate).format("DD");
    if (!`${startDate} - ${endDate}`.includes("16")) {
      return month;
    }
    return "";
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 10, // Adjust font size here (e.g., 10px)
          },
          boxWidth: 14, // Adjust the size of the color box (square)
          padding: 10, // Space around the legend items
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const datasetIndex = tooltipItem.datasetIndex;
            const dataIndex = tooltipItem.dataIndex;
            let startDate;
            let endDate;
            const dateRange = UKScores?.[astId]?.dates[dataIndex];
            startDate = moment(dateRange.startDate).format("MMM DD");
            endDate = moment(dateRange.endDate).format("DD");
            if (datasetIndex === 0) {
              const astUKScore = UKScores?.[astId]?.ukScores[dataIndex];

              if (dateRange && astUKScore !== undefined) {
                return `Range: ${startDate} - ${endDate}, My UK Score: ${astUKScore}`;
              }
            } else if (datasetIndex === 1) {
              const teamUKScore = averageRatings[dataIndex]?.average;

              if (teamUKScore !== undefined) {
                return `Range: ${startDate} - ${endDate}, Team UK Score: ${teamUKScore}`;
              }
            } else {
              const companyUKScore = averageCompanyRatings[dataIndex]?.average;

              if (companyUKScore !== undefined) {
                return `Range: ${startDate} - ${endDate}, Company UK Score: ${companyUKScore}`;
              }
            }
            return "";
          },
        },
        size: "100px",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date Range",
        },
      },
      y: {
        title: {
          display: true,
          text: "UK Score",
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: "My UK Score",
        data: UKScores?.[astId]?.ukScores,
        borderColor: "purple",
        backgroundColor: "purple",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: UKScores?.[astId]?.dates.map((date) =>
          date.startDate === startDate || date.endDate === startDate ? 8 : 5
        ),
        pointBackgroundColor: "purple",
      },
      {
        label: "Team UK Score",
        data: averageRatings.map((data) => data.average),
        borderColor: "orange",
        backgroundColor: "orange",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: UKScores?.[astId]?.dates.map((date) =>
          date.startDate === startDate || date.endDate === startDate ? 8 : 5
        ),
        pointBackgroundColor: "orange",
      },
      {
        label: "Company UK Score",
        data: averageCompanyRatings.map((data) => data.average),
        borderColor: "teal",
        backgroundColor: "teal",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: UKScores?.[astId]?.dates.map((date) =>
          date.startDate === startDate || date.endDate === startDate ? 8 : 5
        ),
        pointBackgroundColor: "teal",
      },
    ],
  };
  const oldheader = [
    "S.no",
    "Description",
    "Matrix & Target",
    "Rating(1-5) Low to High",
  ];
  const header = [
    "S.no",
    "PERF Category",
    "PERF Parameters",
    "Parameter Weightage",
  ];
  const isNewFormDate = moment(endDate).isAfter(moment(new Date("2024-12-31")));
  const UserAppraisal = isNewFormDate
    ? [
        {
          serialNo: 1,
          Objective: "Capability",
          subObjective: [
            {
              desc: "Capability",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Execution Efficiency
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Timeliness, resource optimization, utilization of
                          tools/ technology for better outcomes, adaptability,
                          and communication clarity, consistency in updates,
                          presentation, and quality of documentation.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Meeting project timelines without delays. <br />
                        • Effective prioritization of tasks to ensure on-time
                        &nbsp;&nbsp;delivery. <br />
                        • Optimal use of resources without wastage. <br />•
                        Ability to seek and leverage support when needed. <br />
                        • Ability to adapt and find solutions under pressure.{" "}
                        <br />• Proactive identification and mitigation of
                        potential &nbsp;&nbsp;risks.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 30,
            },
            {
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Quality of Output
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Adherence to specifications, design standards,
                          accuracy, and completeness of deliverables.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Deliverables meet or exceed the required
                        &nbsp;&nbsp;standards.
                        <br />• Minimal errors or rework needed after
                        &nbsp;&nbsp;submission.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 30,
            },
            {
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Accountability and Ownership
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Taking responsibility, proactive decision-making, and
                          ensuring task completion.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Accountability often involves maintaining
                        &nbsp;&nbsp;communication with stakeholders, such as
                        &nbsp;&nbsp;proactive updates and follow-ups.
                        <br />• Communication plays a role in demonstrating
                        &nbsp;&nbsp;ownership of tasks.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 40,
            },
          ],
          rating: [
            { sno: 1, ratingValue: 1 },
            { sno: 2, ratingValue: 3 },
          ],
        },
        {
          serialNo: 2,
          Objective: "Creativity",
          subObjective: [
            {
              desc: "Creativity",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Originality
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Measures the uniqueness and innovation in ideas,
                          solutions, or approaches.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Creativity in proposing out-of-the-box solutions.{" "}
                        <br />• Novelty in thinking or execution compared to
                        &nbsp;&nbsp;existing practices.
                        <br /> • Value addition through distinct contributions.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 20,
            },
            {
              desc: "Creativity",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Alignment
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Evaluates how well actions and outcomes are in sync
                          with the goals of the project, client requirements,
                          organizational vision, and potential for meaningful
                          change.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Applicability of the solution in varied or larger
                        &nbsp;&nbsp;scenarios.
                        <br /> • Tangible results such as cost savings,
                        efficiency &nbsp;&nbsp;gains, or increased reach.
                        <br /> • Long-term value and sustainability.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 60,
            },
            {
              desc: "Creativity",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Complexity and Effort
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Tracks the level of difficulty and resources required
                          to achieve a result.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Understanding and prioritizing objectives at every
                        &nbsp;&nbsp;level.
                        <br /> • Deliverables that meet customer and company
                        &nbsp;&nbsp;expectations.
                        <br /> • Contribution to overarching organizational
                        goals.
                        <br /> • Challenges faced in problem-solving or
                        &nbsp;&nbsp;implementation.
                        <br /> • Effort invested relative to the outcome.
                        <br /> • Ability to overcome technical, logistical, or
                        &nbsp;&nbsp;resource constraints.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 20,
            },
          ],
          rating: [1, 2, 3, 4, 5, 6],
        },
        {
          serialNo: 3,
          Objective: "Collaboration",
          subObjective: [
            {
              desc: "Collaboration",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Team Contribution
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Measures active involvement and willingness to assist
                          team members in achieving shared goals.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Sharing expertise and resources.
                        <br /> • Taking initiative to support others beyond
                        &nbsp;&nbsp;assigned tasks.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 40,
            },
            {
              desc: "Collaboration",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Adaptability & Flexibility
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Evaluates readiness to adjust to changing team
                          dynamics, roles, or priorities.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Embracing new ideas and methods.
                        <br /> • Adjusting personal approaches for the team’s
                        &nbsp;&nbsp;success.
                        <br /> • Maintaining a positive and respectful approach.
                        <br /> • Finding solutions that balance diverse
                        &nbsp;&nbsp;perspectives.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 20,
            },
            {
              desc: "Collaboration",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Relationship Building
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Tracks the ability to foster trust, mutual respect,
                          open communication within the team, and conflict
                          resolution.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "gray",
                          },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Building rapport and collaborative relationships.
                        <br />• Ensuring clear, consistent, and inclusive
                        &nbsp;&nbsp;communication.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 40,
            },
          ],
          rating: [{ sno: 1, ratingValue: 4 }],
        },

        {
          serialNo: 4,
          Objective: "Compliance",
          subObjective: [
            {
              desc: "Compliance",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Awareness
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Evaluates understanding of organizational policies and
                          procedures and the ability to promote compliance among
                          peers.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Staying informed about updates and best
                        &nbsp;&nbsp;practices.
                        <br />• Advocating for adherence to standards within the
                        &nbsp;&nbsp;team.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 30,
            },
            {
              desc: "Compliance",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Adherence
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Measures consistency in following established rules,
                          processes, timelines and the ability to resolve
                          compliance-related issues by identifying and
                          addressing them promptly.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Meeting deadlines and regulatory requirements.
                        <br /> • Maintaining compliance with organizational
                        &nbsp;&nbsp;guidelines.
                        <br /> • Proactive management of non-compliance
                        &nbsp;&nbsp;incidents.
                        <br /> • Implementation of corrective actions and
                        &nbsp;&nbsp;escalation as needed.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 30,
            },
            {
              desc: "Compliance",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Accuracy and Integrity of Records
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Tracks the precision and honesty in maintaining
                          compliance-related documentation and records.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Avoiding errors and ensuring completeness of
                        &nbsp;&nbsp;records.
                        <br />• Upholding transparency and ethical standards in
                        &nbsp;&nbsp;reporting.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 40,
            },
          ],
          rating: [{ sno: 1, ratingValue: 3 }],
        },
        {
          serialNo: 5,
          Objective: "Customer",
          subObjective: [
            {
              desc: "Customer",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Convenience
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Deep commitment to understanding customer requirements
                          and delivering with ease and efficiency to exceed
                          their expectations.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Simplifying processes and interactions for the
                        &nbsp;&nbsp;customer.
                        <br />
                        • Ensuring seamless delivery of products or
                        &nbsp;&nbsp;services.
                        <br />
                        • Consistently delighting the customer by making
                        &nbsp;&nbsp;their experience effortless and enjoyable.
                        <br />
                        • Going above and beyond for valuable and loyal
                        &nbsp;&nbsp;customers.
                        <br />• Avoiding unproductive or detrimental customer
                        &nbsp;&nbsp;relationships
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 30,
            },
            {
              desc: "Customer",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Value Creation
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Emphasizes providing increasingly customer-friendly
                          options and solutions that enhance the customer
                          experience, ensuring they continue to stay with us
                          while contributing to business growth.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Offering innovative, customer-centric solutions
                        &nbsp;&nbsp;tailored to their needs.
                        <br />
                        • Building trust and fostering long-term loyalty
                        &nbsp;&nbsp;through value-driven offerings aligning with
                        &nbsp;&nbsp;customer success.
                        <br />
                        • Aligning value creation with sustained customer
                        &nbsp;&nbsp;satisfaction and business success.
                        <br />• Growing the business by emphasizing the value
                        &nbsp;&nbsp;provided rather than solely pursuing
                        revenue.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 20,
            },
            {
              desc: "Customer",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Cost Optimization
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Promotes operational efficiency and cost management to
                          benefit both the company and its customers.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Continuously identifying areas for cost savings to
                        &nbsp;&nbsp;enhance affordability for customers.
                        <br />
                        • Reducing internal pressures on revenue growth
                        &nbsp;&nbsp;through sustainable financial management.
                        <br />• Balancing cost efficiency with maintaining
                        quality &nbsp;&nbsp;and service excellence.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 10,
            },
            {
              desc: "Customer",
              matrixTarget: (
                <>
                  <Stack
                    direction={"row"}
                    spacing={0.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                      Brand Promotion
                    </Typography>
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: 14, color: "white" }}>
                          Aims to enhance the company’s market perception and
                          reputation through consistent and positive customer
                          experiences.
                        </Typography>
                      }
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: "gray" },
                        },
                      }}
                    >
                      <Info sx={{ fontSize: 16 }} />
                    </Tooltip>
                  </Stack>
                  <Tooltip
                    title={
                      <Typography
                        sx={{ fontSize: 14, color: "white", lineHeight: 1.5 }}
                      >
                        • Building a strong, trustworthy brand image that
                        &nbsp;&nbsp;resonates with customers.
                        <br />
                        • Representing the company positively in every
                        &nbsp;&nbsp;interaction.
                        <br />• Leveraging exceptional service and value
                        delivery &nbsp;&nbsp;to organically promote the brand.
                      </Typography>
                    }
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "gray",
                        },
                      },
                    }}
                  >
                    <Link onClick={(e) => e.preventDefault()}>
                      Key Focus Area
                    </Link>
                  </Tooltip>
                </>
              ),
              weightage: 40,
            },
          ],
          rating: [{ sno: 1, ratingValue: 3 }],
        },
      ]
    : OldUserAppraisal;

  // let ratingObj = {}
  // const handleChange = (rowIndex, index, rating) => {
  //     ratingObj = { ...ratingObj, rowIndex: rowIndex, subIndex: index, rating: rating }

  //     setRatings((arr) => {
  //         const isAlreadyExisted = arr.find((rObj) => rObj.rowIndex === ratingObj.rowIndex && rObj.subIndex === ratingObj.subIndex)
  //         if (isAlreadyExisted) {
  //             arr.pop(isAlreadyExisted);
  //             arr.push(ratingObj);
  //         }
  //         else {
  //             arr.push(ratingObj);
  //         }
  //         return arr;
  //     })

  // }

  const handleDownloadPDF = () => {
    const pdfWidth = 210;
    const pdfHeight = 250;
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });

    const logoUrl1 =
      "https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180";
    const logoUrl2 =
      "https://media-hosting.imagekit.io//345db9943dbb45b1/emportal-me%201%20(1).png?Expires=1734776498&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=bjE--syrSN-EUHcJ0ul6NP0lVCNiAePshUfvsW2QXdgV4iUjgcLO7CEEJiHCGN1DX4-fiz2~k4tTl-LXw2Iqc30dbkED~de1n4FD8zQ1kWk6l8tZCFNIjQtJ9y6~0ntCjMo5t0zEqVLiRmeBeBOy8kZDJB40JyTnh8iGCOPm-~xVStSpApjmkpyKRuYlaN8qtKHBIlC6zdcFHl0er4nQzG31RGeZA5DhmZdygWxYOrl7WsbuIWpGb~ns4ATBlq~p9z7z3xGZ7iMLjM3evz~qkpIvlScRdi~mqgcjTi8l3iImy8X~e3LUGLL61twZ0X6MbmUt4svCY-LfOdoNVNd0oA__";

    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;

    const imageWidth1 = 30;
    const imageHeight1 = 25;
    const logoY = 10;

    pdf.addImage(logoUrl1, "PNG", margin, logoY, imageWidth1, imageHeight1);

    const originalWidth = 345;
    const originalHeight = 94;
    const maxLogoWidth = 30;
    const scale = maxLogoWidth / originalWidth;

    const imageWidth2 = originalWidth * scale;
    const imageHeight2 = originalHeight * scale;

    const logoX2 = pageWidth - imageWidth2 - margin;

    pdf.addImage(logoUrl2, "PNG", logoX2, logoY, imageWidth2, imageHeight2);

    const titleY = logoY + Math.max(imageHeight1, imageHeight2) + 10;
    pdf.setFont("Times New Roman, Times, serif");
    pdf.setFontSize(17);
    const title = `Performance Evaluation, Rating & Feedback of ${location.state.fullname}`;
    pdf.text(title, pageWidth / 2, titleY, { align: "center" });

    const chartCanvas = document.querySelector("canvas");
    const chartImage = chartCanvas.toDataURL("image/png");

    const chartWidth = 80;
    const chartHeight = 50;
    const chartX = pageWidth - chartWidth - margin;
    const chartY = titleY + 10;

    pdf.addImage(chartImage, "PNG", chartX, chartY, chartWidth, chartHeight);

    const detailsY = chartY;
    pdf.setFontSize(12);

    const lineSpacing = 8;
    const details = [
      `Associate ID : ${appraisaldata.associateId}`,
      `Full Name : ${appraisaldata.fullname}`,
      `Designation : ${appraisaldata.designation}`,
      `Project Manager : ${appraisaldata.projectManager.fullname}`,
      `Uk Rating : ${(totalRatingSum / 11).toFixed(2)}`,
      `Feedback : ${comments || "No comments available"}`,
    ];

    const maxWidth = 130;

    details.forEach((detail, index) => {
      const wrappedText = pdf.splitTextToSize(detail, maxWidth);
      pdf.text(wrappedText, margin, detailsY + index * lineSpacing);
    });

    const tableStartY = Math.max(
      chartY + chartHeight + 10,
      detailsY + details.length * lineSpacing + 10
    );

    const splitData = UserAppraisal.flatMap((data, rowIndex) => {
      const descriptions = data.subObjective
        .map((sub) => sub.desc || "")
        .join("\n")
        .split("\n");
      const matrixTargets = data.subObjective
        .map((sub) => sub.matrixTarget || "")
        .join("\n")
        .split("\n");
      const ratingsDemo = data.subObjective
        .map(
          (sub, subIndex) =>
            ratings.find(
              (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
            )?.rating || 0
        )
        .join("\n")
        .split("\n");

      const maxRows = Math.max(
        descriptions.length,
        matrixTargets.length,
        ratingsDemo.length
      );
      return Array.from({ length: maxRows }, (_, i) => ({
        "s.no": data.serialNo,
        Description: descriptions[i] || "",
        "Matrix & Target": matrixTargets[i] || "",
        "Rating (1-5)Low to High": ratingsDemo[i] || "",
      }));
    });

    const header = [
      "s.no",
      "Description",
      "Matrix & Target",
      "Rating (1-5)Low to High",
    ];

    const userData = splitData.map((val) =>
      header.map((header) => val[header])
    );

    pdf.autoTable({
      head: [header],
      body: userData,
      margin: { left: 8, right: 8 },
      startY: tableStartY,
      rowPageBreak: "avoid",
      headStyles: {
        fillColor: "#008080",
        textColor: "#ffffff",
        fontStyle: "bold",
        fontSize: 10,
      },
      bodyStyles: {
        fontSize: 11,
      },
      didDrawPage: function (data) {
        const now = new Date();
        const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")} ${now
          .getHours()
          .toString()
          .padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}`;
        pdf.setFontSize(10);
        pdf.text(
          `Generated on: ${timestamp} | emportal.me`,
          pageWidth / 2,
          pdf.internal.pageSize.height - 5,
          { align: "center" }
        );
      },
    });

    pdf.save(`${location.state.fullname}.pdf`);
  };

  const handleDownloadExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    const splitData = UserAppraisal.flatMap((data, rowIndex) => {
      const descriptions = data.subObjective
        .map((sub) => sub.desc || "")
        .join("\n")
        .split("\n");
      const matrixTargets = data.subObjective
        .map((sub) => sub.matrixTarget || "")
        .join("\n")
        .split("\n");
      const ratingsDemo = data.subObjective
        .map(
          (sub, subIndex) =>
            ratings.find(
              (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
            )?.rating || 0
        )
        .join("\n")
        .split("\n");

      const maxRows = Math.max(
        descriptions.length,
        matrixTargets.length,
        ratingsDemo.length
      );
      return Array.from({ length: maxRows }, (_, i) => ({
        "s.no": data.serialNo,
        Description: descriptions[i] || "",
        "Matrix & Target": matrixTargets[i] || "",
        "Rating (1-5)Low to High": ratingsDemo[i] || "",
      }));
    });

    const header = [
      "s.no",
      // "Objective",
      "Description",
      "Matrix & Target",
      "Rating (1-5)Low to High",
    ];

    XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
    splitData.forEach((data, index) => {
      const rowData = header.map((header) => data[header]);
      XLSX.utils.sheet_add_aoa(ws, [rowData], { origin: `A${index + 2}` });
    });

    const columnWidths = header.map((col) => ({ wch: col.length + 10 }));
    ws["!cols"] = columnWidths;
    XLSX.utils.book_append_sheet(wb, ws, "Appraisal Report");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelBlob, `${location.state.fullname}.xlsx`);
  };

  // added by anil start
  const rating_titles = [
    {
      title: "Capability",
      perf: [
        "Execution Efficiency",
        "Quality of Output",
        "Accountability and Ownership",
      ],
    },
    {
      title: "Creativity",
      perf: ["Originality", "Alignment", "Complexity and Effort"],
    },
    {
      title: "Collaboration",
      subObjective: [
        "Team Contribution",
        "Adaptability & Flexibility",
        "Relationship Building",
      ],
    },
    {
      title: "Compliance",
      perf: ["Awareness", "Adherence", "Accuracy and Integrity of Records"],
    },
    {
      title: "Customer",
      perf: [
        "Compliance",
        "Value Creation",
        "Cost Optimization",
        "Brand Promotion",
      ],
    },
  ];
  /* const rateStartTime = useMemo(() => {
        const now = new Date();
        return now.toLocaleString();
      }, []); */
  const rateStartTime = getCurrentDateTime();

  const submitRatonClick = (data) => {
    /*  axiosInstance.post("/user/submitRatings123", data).then(res => {
        console.log("Ratings Submitted Successfully!");
        }).catch(err => {
        console.log(err);
        }) */
    let LOG_URL = process.env.REACT_APP_EMPORTAL_SERVICE_LOG_URL;
    fetch(LOG_URL + "/submitRatings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).catch((err) => {
      console.log(err);
    });
  };

  // aaded by anil end

  const handleChange = (rowIndex, subIndex, newValue, weightage) => {
    if (newValue >= 3 && newValue < 4) {
      setValidation((prevValidation) => {
        const updatedValidation = { ...prevValidation };
        delete updatedValidation[`${rowIndex}-${subIndex}`]; // Remove the error if no validation
        return updatedValidation;
      });
    }
    // aaded by anil start
    let em = appraisaldata?.firstname + " " + appraisaldata?.lastname;
    let pm = appraisaldata?.projectManager?.fullname;
    let perf_title = rating_titles[rowIndex].title;
    let perf_subtitle = rating_titles[rowIndex]?.perf?.[subIndex] || "";
    let project =
      astId === user?.associateId
        ? null
        : {
            id:
              role === "Reporting Manager"
                ? props.projects?.id
                : role === "User"
                ? 26
                : props.projects.find(
                    (data) => data.title === location.state.project
                  ).id,
          };
    let rateEndTime = getCurrentDateTime();

    let RateData = {
      project_id: project?.id,
      project_name: appraisaldata.project,
      startDate: startDate,
      endDate: endDate,
      perf_title: perf_title,
      perf_subtitle: perf_subtitle,
      rowIndex: rowIndex,
      subindex: subIndex,
      newValue: newValue,
      weightage: weightage,
      associateId: appraisaldata.associateId,
      associate_name: appraisaldata?.firstname + " " + appraisaldata?.lastname,
      rated_by: user.associateId,
      rated_by_name: user.firstname + " " + user.lastname,
      rateStartTime: rateStartTime,
      rateEndTime: rateEndTime,
    };
    //submitRatonClick(RateData);

    // aaded by anil end

    setRatings((prevRatings) => {
      // Update existing ratings or add new one
      const updatedRatings = prevRatings.map((r) =>
        r.rowIndex === rowIndex && r.subIndex === subIndex
          ? {
              ...r,
              rating: newValue,
              weightagedRating: (newValue * weightage) / 100,
              rateStartTime: rateStartTime,
              rateEndTime: rateEndTime,
            } // Update existing rating
          : r
      );

      // If no rating was updated, add a new entry
      if (
        !updatedRatings.some(
          (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
        )
      ) {
        updatedRatings.push({
          rowIndex,
          subIndex,
          rating: newValue,
          weightagedRating: (newValue * weightage) / 100,
          rateStartTime: rateStartTime,
          rateEndTime: rateEndTime,
        });
      }
      return updatedRatings;
    });
  };

  const handlePMCommentChange = (rowIndex, subIndex, newValue) => {
    const currentRating =
      ratings.find((r) => r.rowIndex === rowIndex && r.subIndex === subIndex)?.rating || 0;
  
    const validationError = validateComment(currentRating, newValue);
  
    setValidation((prevValidation) => ({
      ...prevValidation,
      [`PM-${rowIndex}-${subIndex}`]: validationError, // Store PM validation errors separately
    }));
  
    setRatings((prevRatings) => {
      const updatedRatings = prevRatings.map((r) =>
        r.rowIndex === rowIndex && r.subIndex === subIndex
          ? { ...r, pmComment: newValue }
          : r
      );
  
      if (!updatedRatings.some((r) => r.rowIndex === rowIndex && r.subIndex === subIndex)) {
        updatedRatings.push({ rowIndex, subIndex, pmComment: newValue, comment: "" });
      }
  
      return updatedRatings;
    });
  };
  

  const handleCommentChange = (rowIndex, subIndex, newValue) => {
    let comtEndTime = getCurrentDateTime();
    const currentRating =
      ratings.find((r) => r.rowIndex === rowIndex && r.subIndex === subIndex)
        ?.rating || 0;
        if (role !== "Project Manager") {
    const validationError = validateComment(currentRating, newValue);

    // Set validation error for specific rowIndex and subIndex
    if (validationError) {
      setValidation((prevValidation) => ({
        ...prevValidation,
        [`${rowIndex}-${subIndex}`]: validationError,
      }));
    } else {
      setValidation((prevValidation) => {
        const updatedValidation = { ...prevValidation };
        delete updatedValidation[`${rowIndex}-${subIndex}`]; // Remove the error if no validation
        return updatedValidation;
      });
    }

    setRatings((prevRating) => {
      const updatedRatings = prevRating.map((r) =>
        r.rowIndex === rowIndex && r.subIndex === subIndex
          ? {
              ...r,
              comment: newValue,
              comtStartTime: rateStartTime,
              ratecmtEndTime: comtEndTime,
            } // Update existing rating
          : r
      );
      // If no rating was updated, add a new entry
      if (
        !updatedRatings.some(
          (r) =>
            r.rowIndex === rowIndex &&
            r.subIndex === subIndex &&
            r.comment !== null
        )
      ) {
        updatedRatings.push({
          rowIndex,
          subIndex,
          rating: 0,
          comment: newValue,
          comtStartTime: rateStartTime,
          ratecmtEndTime: comtEndTime,
        });
      }
      return updatedRatings;
    });
  };

    setCommentForEachObjective((prevComments) => {
      // Update existing ratings or add new one
      const updatedComments = prevComments.map((r) =>
        r.rowIndex === rowIndex && r.subIndex === subIndex
          ? {
              ...r,
              comment: newValue,
              comtStartTime: rateStartTime,
              ratecmtEndTime: comtEndTime,
            } // Update existing rating
          : r
      );

      // If no rating was updated, add a new entry
      if (
        !updatedComments.some(
          (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
        )
      ) {
        updatedComments.push({
          rowIndex,
          subIndex,
          comment: newValue,
          comtStartTime: rateStartTime,
          ratecmtEndTime: comtEndTime,
        });
      }
      return updatedComments;
    });
  };

  const validateComment = (rating, comment) => {
    if (role === "Project Manager") {
      return null;
  }
    const wordCount = comment.trim().split(/\s+/).length; // Count words
    if (
      (rating === 0.5 ||
        rating === 1 ||
        rating === 5 ||
        rating === 1.5 ||
        rating === 4.5) &&
      wordCount < 20
    ) {
      return `Rating ${rating} requires at least 20 words.`;
    }
    if (
      (rating === 2 || rating === 4 || rating === 2.5 || rating === 3.5) &&
      wordCount < 10
    ) {
      return `Rating ${rating} requires at least 10 words.`;
    }
    return null; // No validation error
  };

  const handleDownloadMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  let totalRatingSum = 0;
  ratings.map((r) => (totalRatingSum += r.weightagedRating));

  const handlePrevDateChange = (e, type) => {
    setRatings([]);
    setComments("");
    setIsSubmittedForRange(false);
    setSubmitted(false);
    if (type === "icon") {
      const newStartDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate)
              .subtract(1, "month")
              .startOf("month")
              .add(15, "days")
              .format("YYYY-MM-DD")
          : moment(startDate).startOf("month").format("YYYY-MM-DD");
      const newEndDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate)
              .subtract(1, "month")
              .endOf("month")
              .format("YYYY-MM-DD")
          : moment(startDate)
              .startOf("month")
              .add(14, "day")
              .format("YYYY-MM-DD");
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      const value = e.target.value;
      setStartDate(value);
    }
  };

  const handleNextDateChange = (e, type) => {
    setRatings([]);
    setComments("");
    setIsSubmittedForRange(false);
    setSubmitted(false);
    if (type === "icon") {
      const newStartDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate).add(15, "days").format("YYYY-MM-DD")
          : moment(startDate)
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD");
      const newEndDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate).endOf("month").format("YYYY-MM-DD")
          : moment(startDate)
              .add(1, "month")
              .startOf("month")
              .add(14, "days")
              .format("YYYY-MM-DD");
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      const value = e.target.value;
      setEndDate(value);
    }
  };

  const renderDateFields = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <Tooltip title="click to go to previous month">
          {location.state.type === "User" ? (
            <></>
          ) : (
            <IconButton onClick={(e) => handlePrevDateChange(e, "icon")}>
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
          )}
        </Tooltip>
        <TextField
          type="date"
          value={startDate}
          label="Start Date"
          size="small"
          error={
            startDate && new Date(startDate) > new Date(endDate)
              ? "Start date cannot be after end date"
              : ""
          }
          helperText={
            startDate && new Date(startDate) > new Date(endDate)
              ? "Start date cannot be after end date"
              : ""
          }
          InputLabelProps={{
            style: { font: "small-caption", fontFamily: "Lucida Grande" },
            shrink: true,
          }}
          InputProps={{
            style: { font: "small-caption", fontFamily: "Lucida Grande" },
          }}
          disabled={true}
          onChange={handlePrevDateChange}
        />
        &nbsp; &nbsp;
        <TextField
          type="date"
          value={endDate}
          label="End Date"
          size="small"
          error={
            startDate && new Date(endDate) < new Date(startDate)
              ? "End date cannot be before start date"
              : ""
          }
          helperText={
            startDate && new Date(endDate) < new Date(startDate)
              ? "End date cannot be before start date"
              : ""
          }
          InputLabelProps={{
            style: { font: "small-caption", fontFamily: "Lucida Grande" },
            shrink: true,
          }}
          InputProps={{
            style: { font: "small-caption", fontFamily: "Lucida Grande" },
          }}
          disabled={true}
          onChange={handleNextDateChange}
        />
        <Tooltip title="click to go to next month">
          {location.state.type === "User" ? (
            <></>
          ) : (
            <IconButton
              onClick={(e) => handleNextDateChange(e, "icon")}
              disabled={
                new Date(endDate) >=
                new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
              }
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          )}
        </Tooltip>
      </Box>
    );
  };

  const handleClick = () => {
    setIsDialogOpen(false);

    const data = {
      startDateAndTime: startDateAndTime,
      endDateAndTime: getCurrentDateTime(),
      ratings: JSON.stringify(ratings),
      comments: comments,
      user: { associateId: astId },
      project:
        astId === user?.associateId
          ? {
              projectManager: {
                associateId: selectedPM?.associateId,
              },
            }
          : {
              id:
                role === "Reporting Manager"
                  ? props.projects?.id
                  : role === "User"
                  ? 26
                  : props.projects.find(
                      (data) => data.title === location.state.project
                    ).id,
              projectManager: {
                associateId: selectedPM?.associateId,
              },
            },
      startDate: startDate,
      endDate: endDate,
      ratingAvg: Math.floor(totalRatingSum * (20 / 100) * 100) / 100,
      commentObj: JSON.stringify(commentforEachObjective),
    };

    setSubmitLoading(true);
    if (ratings.length !== 0) {
      axiosInstance
        .post("/user/submitRatings", data)
        .then((res) => {
          setSubmitted(true);
          setIsSubmittedForRange(true);
          setSeverity("success");
          setMessage("Ratings Submitted Successfully!");
          setSnackbarOpen(true);
          setSubmitLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSubmitLoading(false);
        });
    } else {
      setSeverity("error");
      setMessage("Provide atleast 1 rating. Please try again.");
      setSnackbarOpen(true);
      setSubmitLoading(false);
    }
  };

  const validateRatings = () => {
    for (let rowIndex = 0; rowIndex < UserAppraisal.length; rowIndex++) {
      const subObjectives = UserAppraisal[rowIndex].subObjective;

      for (let subIndex = 0; subIndex < subObjectives.length; subIndex++) {
        const rating = ratings.find(
          (r) => r.rowIndex === rowIndex && r.subIndex === subIndex
        )?.rating;

        if (!rating || rating <= 0) {
          setSeverity("error");
          setMessage(" Please provide Ratings for all the fields.");
          setSnackbarOpen(true);
          return false;
        }
      }
    }

    return true;
  };

  const selfratingCheckingFunction = () => {
    return (
      role === "Admin" ||
      ((role === "User" ||
        ((role === "Project Manager" || role === "Reporting Manager") &&
          astId === user.associateId)) &&
        appraisaldata.userSideSubmission) ||
      ((role === "Project Manager" || role === "Reporting Manager") &&
        appraisaldata.projectManagerSubmission)
    );
  };

  const getDifferenceChipStyle = (difference) => {
    if (difference > 0) {
      return { backgroundColor: "green", color: "white" }; // Positive difference
    } else if (difference > -1.0) {
      return { backgroundColor: "lightcoral", color: "white" }; // Negative but > -1.0
    } else {
      return { backgroundColor: "red", color: "white" }; // Difference <= -1.0
    }
  };

  const getChipLabel = (difference) => {
    return Math.abs(difference).toFixed(1); // Always show positive value, rounded to 1 decimal place
  };
  const weightedRating = (value) => {
    let sum = 0;
    ratings.forEach((data) => {
      if (
        data?.rowIndex === value &&
        typeof data?.weightagedRating === "number"
      ) {
        sum += data.weightagedRating;
      }
    });
    return sum === 0 ? 0 : Math.floor(sum * 100) / 100;
  };

  const NewTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              S.no
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              PERF Category
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              PERF Parameters
            </Typography>
          </TableCell>

          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              PERF weightage
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              Rating(1-5) Low to High
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              Weighted Rating
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "1px solid",
              padding: "4px",
              backgroundColor: "#dde2eb",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lucida Grande, sans-serif",
                fontWeight: "bold",
              }}
            >
              Comments
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };
  const [pageIndex, setPageIndex] = useState(index ?? 0);
  const [commentHistory, setCommentHistory] = useState({});
  const scrollRef = useRef(null);
  const [loading, setLoading] = useState({});

  const groupedParameters = Object.values(
    perfParameters.reduce((acc, param) => {
      if (!acc[param.Objective]) acc[param.Objective] = [];
      acc[param.Objective].push(param);
      return acc;
    }, {})
  );

  const totalPanels = groupedParameters[pageIndex].length;
  const expandedCount = Object.values(expandedPanels).filter(Boolean).length;

  useEffect(() => {
    if (selectedPM?.associateId) {
      axiosInstance
        .post(`/user/comment/history`, {
          startDate,
          userId: astId,
          managerId: selectedPM?.associateId,
          rowIndex: pageIndex,
          subIndex: groupedParameters?.[pageIndex].length,
        })
        .then((res) => setCommentHistory(res.data))
        .catch((err) => console.log(err));
    }
  }, [pageIndex, selectedPM]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [commentHistory]);

  const handleNavigation = (direction) => {
    if (direction === "Prev" && pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    } else if (
      direction === "Next" &&
      pageIndex < groupedParameters.length - 1
    ) {
      setPageIndex(pageIndex + 1);
    }
  };
  const enhanceWithBunai = async (index, subIndex, comment, context) => {
    if (!comment.trim()) return;
    setLoading({ [subIndex]: true });

    try {
      const response = await axiosInstance.post(
        "https://bunai.edvenswatech.com/service/enhancetext",
        {
          input_text: comment,
          context,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data?.response) {
        handleCommentChange(index, subIndex, response.data?.response);
      }
    } catch (error) {
      console.error("Error enhancing text:", error);
    } finally {
      setLoading({});
    }
  };
  return (
    <Card>
      <Grid container xs={12} mt={2} pl={2}>
        <Grid container item xs={6}>
          <Grid item xs={4}>
            <Typography variant="h6">PERF Rating Sheet</Typography>
          </Grid>
          {props?.showDateRange === false ? (
            <Grid item xs={4}></Grid>
          ) : (
            <>
              <Grid item xs={4}>
                {renderDateFields()}
              </Grid>
              <Grid item xs={4}>
                {role === "User" ? (
                  <></>
                ) : (
                  <IconButton
                    onClick={handleDownloadMenuClick}
                    // disabled={!isSubmittedForRange}  // Disable the button until submitted
                    // disabled={loading || Object.keys(errors).length !== 0}
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
                <Menu
                  anchorEl={menuanchorEl}
                  open={Boolean(menuanchorEl)}
                  onClose={() => {
                    setMenuAnchorEl(null);
                  }}
                >
                  <MenuItem
                    style={{ font: "small-caption" }}
                    onClick={() => handleDownloadPDF()}
                  >
                    Download PDF
                  </MenuItem>
                  <MenuItem
                    style={{ font: "small-caption" }}
                    onClick={() => handleDownloadExcel()}
                  >
                    Download Excel
                  </MenuItem>
                </Menu>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Stack direction={"column"}>
              <Typography>
                {role !== "User" ? (
                  <div>
                    Associate Name:{" "}
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/home/users/details", {
                          state: {
                            user: { username: appraisaldata.username },
                            allusers: [],
                          },
                        });
                        // navigate("/home/ratings", { state: { projectManager: appraisaldata?.projectManager } })
                      }}
                    >
                      {appraisaldata?.fullname}
                    </Link>
                  </div>
                ) : (
                  `Associate Name: ${appraisaldata?.fullname}`
                )}
              </Typography>
              <Typography>
                Project:{" "}
                {role === "Reporting Manager"
                  ? selectedPM?.projects
                  : selectedPM?.projects}
              </Typography>
              <Typography>
                {role !== "User" ? (
                  <div>
                    Project Manager:{" "}
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/home/ratings", {
                          state: {
                            projectManager: selectedPM,
                          },
                        });
                      }}
                    >
                      {selectedPM?.fullName}
                    </Link>
                  </div>
                ) : (
                  `Project Manager: ${selectedPM?.fullName}`
                )}
              </Typography>
              {isSubmittedForRange ? (
                <Typography>UK Score: {ukScore}</Typography>
              ) : (
                <Typography>
                  Rating :{" "}
                  {totalRatingSum
                    ? Math.floor(totalRatingSum * (20 / 100) * 100) / 100
                    : 0}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Stack direction={"row"} spacing={2}>
            {((role === "Project Manager" &&
              appraisaldata?.fullname ===
                user.firstname + " " + user.lastname) ||
              role !== "Project Manager") && (
              <TextField
                size="small"
                select
                label="Project Manager"
                value={selectedPM}
                onChange={(e) => setSelectedPM(e.target.value)}
                InputProps={{
                  style: {
                    font: "small-caption",
                    fontFamily: "Lucida Grande",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: "12px",
                  },
                }}
              >
                {projectManagers.map((pm) => (
                  <MenuItem
                    style={{
                      font: "small-caption",
                      fontFamily: "Lucida Grande",
                    }}
                    key={pm}
                    value={pm}
                  >
                    {pm?.fullName}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              size="small"
              sx={{
                padding: "2px 6px",
                fontSize: "12px",
                minWidth: "auto",
                height: "30px",
              }}
            >
              View Tasks
            </Button>
            {open && (
              <TaskDialog
                projects={selectedPM?.projects}
                open={open}
                setOpen={setOpen}
                startDate={startDate}
                endDate={endDate}
                astId={astId}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Line data={chartData} options={options} />
        </Grid>
      </Grid>
      <CardContent>
        <Box
          sx={{
            width: "97%",
            margin: "auto",
            border: "1px solid #ccc",
            borderRadius: "10px",
            padding: "20px",
            backgroundColor: "white",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="10px"
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {groupedParameters[pageIndex][0]?.Objective}
            </Typography>
            <Stack direction="row" spacing={1}>
              {expandedCount > 0 ? (
                <Button
                  onClick={handleExpandCollapse}
                  variant="contained"
                  size="small"
                  sx={{ marginBottom: 2 }}
                >
                  Collapse All
                </Button>
              ) : (
                <Button
                  onClick={handleExpandCollapse}
                  variant="contained"
                  size="small"
                  sx={{ marginBottom: 2 }}
                >
                  Expand All
                </Button>
              )}
              {saving ? <Typography>Syncing...</Typography> : ""}
              <Tooltip title={"Sync"}>
                <Sync
                  sx={{
                    fontSize: 24,
                    animation: saving ? "spin 1s linear infinite" : "none",
                    "@keyframes spin": {
                      "0%": { transform: "rotate(0deg)" },
                      "100%": { transform: "rotate(360deg)" },
                    },
                  }}
                />
              </Tooltip>
            </Stack>
          </Stack>
          <Box
            sx={{
              overflowY: "auto",
              border: "1px solid #ccc",
              padding: "10px",
            }}
          >
            {groupedParameters[pageIndex].map((perfObj) => (
              <Accordion
                key={`${perfObj.index}-${perfObj.subIndex}`}
                expanded={
                  expandedPanels[`${perfObj.index}-${perfObj.subIndex}`] ||
                  false
                }
                onChange={() =>
                  handleExpand(`${perfObj.index}-${perfObj.subIndex}`)
                }
              >
                <AccordionSummary
                  expandIcon={
                    expanded === `${perfObj.index}-${perfObj.subIndex}` ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Stack direction="row" alignItems="center" gap="7px">
                          <Typography
                            sx={{
                              fontSize: 18,
                              fontWeight: "bolder",
                              flexGrow: 1,
                            }}
                          >
                            {perfObj.title}
                          </Typography>
                          <Tooltip title={perfObj.desc}>
                            <Info
                              sx={{
                                fontSize: 19,
                                cursor: "pointer",
                              }}
                              onClick={() => console.log("hello")}
                            />
                          </Tooltip>
                        </Stack>

                        <Tooltip
                          title={
                            role === "User" &&
                            (isSubmittedForRange ||
                              appraisaldata.userSideSubmission)
                              ? "User submission completed. Ratings are read-only."
                              : role === "Project Manager" &&
                                appraisaldata.projectManagerSubmission
                              ? "Project Manager submission completed. Ratings are read-only."
                              : ""
                          }
                          arrow
                        >
                          <span>
                            <Rating
                              value={
                                ratings.find(
                                  (r) =>
                                    r.rowIndex === perfObj.index &&
                                    r.subIndex === perfObj.subIndex
                                )?.rating || 0
                              }
                              onChange={(event, newValue) =>
                                handleChange(
                                  perfObj.index,
                                  perfObj.subIndex,
                                  newValue,
                                  perfObj.weightage
                                )
                              }
                              precision={0.5}
                              readOnly={selfratingCheckingFunction()}
                            />
                          </span>
                        </Tooltip>
                      </Stack>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography sx={{ fontSize: 15 }}>{perfObj.desc}</Typography> */}
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    {
                      <Grid item xs={12} sx={{ position: "relative" }}>
                        <Tooltip
                          title={
                            role === "User" &&
                            (isSubmittedForRange ||
                              appraisaldata.userSideSubmission)
                              ? "User submission completed. Comments are read-only."
                              : role === "Project Manager" &&
                                appraisaldata.projectManagerSubmission
                              ? "Project Manager submission completed. Comments are read-only."
                              : ""
                          }
                          arrow
                        >
                          <span>
                            {role === "Project Manager" && (
                              <>
                                {(role === "Admin" ||
                                  (role === "Project Manager" &&
                                    astId !== user.associateId) ||
                                  (role === "Reporting Manager" &&
                                    astId !== user.associateId) ||
                                  ((role === "User" ||
                                    role === "Project Manager" ||
                                    role === "Reporting Manager") &&
                                    appraisaldata.projectManagerSubmission &&
                                    appraisaldata.userSideSubmission)) && (
                                  <>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold", marginTop: 2 }}
                                    >
                                      Associate's Comment:
                                    </Typography>

                                    <Typography
                                      variant="body1"
                                      sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        marginBottom: 2,
                                      }}
                                    >
                                      {ratings.find(
                                        (r) =>
                                          r.rowIndex === perfObj.index &&
                                          r.subIndex === perfObj.subIndex
                                      )?.comment || "No comment provided"}
                                    </Typography>

                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold", marginTop: 2 }}
                                    >
                                      Project Manager's Reply:
                                    </Typography>
                                  </>
                                )}
                              </>
                            )}
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              placeholder="Enter your comments..."
                              disabled={selfratingCheckingFunction()}
                              value={
                                role === "Project Manager"
                                  ? ratings.find(
                                      (r) =>
                                        r.rowIndex === perfObj.index &&
                                        r.subIndex === perfObj.subIndex
                                    )?.pmComment || ""
                                  : ratings.find(
                                      (r) =>
                                        r.rowIndex === perfObj.index &&
                                        r.subIndex === perfObj.subIndex
                                    )?.pmComment ||
                                    ratings.find(
                                      (r) =>
                                        r.rowIndex === perfObj.index &&
                                        r.subIndex === perfObj.subIndex
                                    )?.comment ||
                                    ""
                              }
                              onCopy={(e) => e.preventDefault()}
                              onCut={(e) => e.preventDefault()}
                              onPaste={(e) => e.preventDefault()}
                              onChange={(event) => {
                                if (role === "Project Manager") {
                                  handlePMCommentChange(
                                    perfObj.index,
                                    perfObj.subIndex,
                                    event.target.value
                                  );
                                } else {
                                  handleCommentChange(
                                    perfObj.index,
                                    perfObj.subIndex,
                                    event.target.value
                                  );
                                }
                              }}
                              disabled={selfratingCheckingFunction()}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip
                                      title={
                                        loading?.[perfObj.subIndex]
                                          ? "Enhancing..."
                                          : "Enhance"
                                      }
                                    >
                                      <IconButton
                                        size="small"
                                        sx={{
                                          position: "absolute",
                                          bottom: 10,
                                          right: 10,
                                          color: "white",
                                          backgroundColor: "teal",
                                          borderRadius: "50%",
                                          width: 36,
                                          height: 36,
                                          "&:hover": {
                                            backgroundColor: "teal",
                                          },
                                        }}
                                        onClick={() =>
                                          enhanceWithBunai(
                                            perfObj.index,
                                            perfObj.subIndex,
                                            ratings.find(
                                              (r) =>
                                                r.rowIndex === perfObj.index &&
                                                r.subIndex === perfObj.subIndex
                                            )?.comment || "",
                                            perfObj.keyfocusarea
                                          )
                                        }
                                        disabled={selfratingCheckingFunction()}
                                      >
                                        <motion.div
                                          animate={
                                            loading?.[perfObj.subIndex]
                                              ? { rotate: 360 }
                                              : {}
                                          }
                                          transition={
                                            loading?.[perfObj.subIndex]
                                              ? {
                                                  repeat: Infinity,
                                                  duration: 1,
                                                  ease: "linear",
                                                }
                                              : {}
                                          }
                                        >
                                          <AutoAwesome />
                                        </motion.div>
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </span>
                        </Tooltip>
                        {validation[`${perfObj.index}-${perfObj.subIndex}`] && (
                          <Typography color="error" fontSize={12} mt={1}>
                            {validation[`${perfObj.index}-${perfObj.subIndex}`]}
                          </Typography>
                        )}
                      </Grid>
                    }
                    <Grid item xs={12}>
                      <Accordion
                        expanded={
                          !!innerExpanded[
                            `inner-${perfObj.index}-${perfObj.subIndex}`
                          ]
                        }
                        onChange={() =>
                          handleInnerExpand(
                            `inner-${perfObj.index}-${perfObj.subIndex}`
                          )
                        }
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography
                            sx={{ fontSize: 14, fontWeight: "bolder" }}
                          >
                            View Previous Comments
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            ref={scrollRef}
                            sx={{
                              height: selfratingCheckingFunction()
                                ? "300px"
                                : "250px",
                              overflowY: "auto",
                              p: 1,
                              borderRadius: "10px",
                              border: "1px solid #ccc",
                              bgcolor: "#f9f9f9",
                            }}
                          >
                            <Grid item sx={12} container spacing={1}>
                              {commentHistory?.[perfObj.subIndex]?.length > 0 &&
                                commentHistory?.[perfObj.subIndex].map(
                                  (c, index) => (
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      justifyContent={
                                        c.submittedByUser &&
                                        !c.submittedByManager
                                          ? "flex-end"
                                          : "flex-start"
                                      }
                                    >
                                      <Grid key={index} item xs={6}>
                                        <Stack direction={"column"} spacing={1}>
                                          <Paper
                                            elevation={3}
                                            sx={{
                                              p: 1,
                                              maxWidth: "100%",
                                              backgroundColor:
                                                c.submittedByUser &&
                                                !c.submittedByManager
                                                  ? "lightgreen"
                                                  : "white",
                                              borderRadius:
                                                c.submittedByUser &&
                                                !c.submittedByManager
                                                  ? "10px 10px 10px 0px"
                                                  : "10px 10px 0px 10px",
                                            }}
                                          >
                                            <Typography
                                              sx={{ fontSize: 14 }}
                                              variant="body1"
                                            >
                                              {c.comment}
                                            </Typography>
                                          </Paper>
                                          <Typography
                                            textAlign={
                                              c.submittedByUser &&
                                              !c.submittedByManager
                                                ? "end"
                                                : "start"
                                            }
                                            variant="caption"
                                            sx={{ fontSize: 10 }}
                                          >
                                            {c.submittedByUser &&
                                            !c.submittedByManager
                                              ? `${
                                                  appraisaldata?.fullname
                                                } ${moment(
                                                  c.rateEndTime,
                                                  "YYYY-MM-DD HH:mm"
                                                ).format("DD-MMM-YYYY hh:mmA")}`
                                              : `${
                                                  selectedPM?.fullName
                                                } ${moment(
                                                  c.rateEndTime,
                                                  "YYYY-MM-DD HH:mm"
                                                ).format(
                                                  "DD-MMM-YYYY hh:mmA"
                                                )}`}
                                          </Typography>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                            </Grid>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Grid
            container
            justifyContent="space-between"
            sx={{ marginTop: "10px" }}
          >
            <Grid item>
              <Button
                onClick={() => handleNavigation("Prev")}
                size="small"
                variant="contained"
                disabled={pageIndex === 0}
              >
                Prev
              </Button>
            </Grid>
            <Grid item>
              {pageIndex + 1}/{groupedParameters.length}
            </Grid>
            <Grid item>
              {pageIndex < groupedParameters.length - 1 ? (
                <Button
                  onClick={() => handleNavigation("Next")}
                  size="small"
                  variant="contained"
                >
                  Next
                </Button>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Box>

        <Dialog open={isDialogOpen}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            The total rating given is
            <Typography variant="h6">
              {(Math.floor(totalRatingSum * (20 / 100) * 100) / 100).toFixed(2)}
            </Typography>
            The ratings submitted are unable to change once submitted. Do you
            want to continue?
          </DialogContent>

          <DialogActions>
            <Button
              sx={{ color: "white" }}
              onClick={() => {
                setIsDialogOpen(false);
              }}
            >
              cancel
            </Button>
            <Button sx={{ color: "white" }} onClick={handleClick}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {(role === "Admin" ||
          (role === "Project Manager" && astId !== user.associateId) ||
          (role === "Reporting Manager" && astId !== user.associateId) ||
          ((role === "User" ||
            role === "Project Manager" ||
            role === "Reporting Manager") &&
            appraisaldata.projectManagerSubmission &&
            appraisaldata.userSideSubmission)) && (
          <TextField
            component="form"
            required={true}
            value={comments}
            disabled={
              isDisabled
                ? isDisabled
                : isSubmittedForRange && selfratingCheckingFunction()
            }
            fullWidth
            type="text"
            multiline={true}
            rows="2"
            placeholder="Write your feedback here..."
            sx={{ mt: 2 }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              sx: {
                fontSize: "14px",
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px",
                },
              },
            }}
            onChange={(e) => setComments(e.target.value)}
          />
        )}
      </CardContent>

      <CardActions sx={{ justifyContent: "flex-end" }}>
        {submitloading ? (
          <CircularProgress />
        ) : isDisabled ? (
          isDisabled
        ) : (!isSubmittedForRange && !submitted) ||
          !selfratingCheckingFunction() ? (
          <Grid>
            <Button
              size="small"
              component="form"
              onClick={() => {
                if (role === "Project Manager" && user?.associateId === astId) {
                  setIsDialogOpen(true);
                  return;
                }
                if (
                  appraisaldata?.commentObj === null &&
                  (role === "Project Manager" ||
                    role === "Reporting Manager") &&
                  user?.associateId !== astId
                ) {
                  setSeverity("error");
                  setMessage(
                    "Please ensure the user completes their PERF form submission."
                  );
                  setSnackbarOpen(true);
                  return;
                }
                if (commentforEachObjective.length !== 16) {
                  setSeverity("error");
                  setMessage("Please fill every comment.");
                  setSnackbarOpen(true);
                  return;
                }
                if (validateRatings()) {
                  if (
                    role === "Project Manager" &&
                    user?.associateId === astId
                  ) {
                    setIsDialogOpen(true);
                    return;
                  }
                  if (
                    !comments &&
                    (role === "Admin" ||
                      (role === "Reporting Manager" &&
                        astId !== user.associateId))
                  ) {
                    setSeverity("error");
                    setMessage("Please fill feedback for associate.");
                    setSnackbarOpen(true);
                    return;
                  }
                  if (
                    role === "Project Manager" &&
                    user?.associateId === astId
                  ) {
                    setIsDialogOpen(true);

                    if (Object.keys(validation ?? {}).length > 0) {
                      setSeverity("error");
                      setMessage(
                        "Please fill the comments as per PERF Validations."
                      );
                      setSnackbarOpen(true);
                      return;
                    }
                    return;
                  }

                  setIsDialogOpen(true);
                }
              }}
              sx={{ color: "white", marginRight: "10px" }}
            >
              Submit
            </Button>
          </Grid>
        ) : null}
      </CardActions>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default UserAppraisal;
