import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    TextareaAutosize,
    Tooltip,
    IconButton,
    Paper,
    Stack,
    Select,
    MenuItem,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
} from "@mui/material";
import { Margin } from '@mui/icons-material';
import ClassNameGenerator from '@mui/utils/ClassNameGenerator';

const PerfDashboard = (e) => {

    const [selectionKey, setSelectionKey] = useState('Capability'); // Default selection key
    const [selectedValue, setSelectedValue] = useState('ALL'); // Default to 'ALL' for all subcategories
    const [sortOrder, setSortOrder] = useState('desc'); // Default to descending sort order
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [perfData, setPerfData] = useState([])
    const [loader, setLoader] = useState(false)

    // Data for subcategories of each category
    const data = {
        Capability: [
            "Execution efficiency",
            "Quality of output",
            "Accountability",
            "Ownership"
        ],
        Creativity: [
            "Originality",
            "Alignment",
            "Complexity and Effort"
        ],
        Collaboration: [
            "Team Contribution",
            "Adaptability and Flexibility",
            "Relationship Building"
        ],
        Compliance: [
            "Awareness",
            "Adherence",
            "Accuracy and Integrity of records"
        ],
        CustomerFocus: [
            "Convenience",
            "Value Creation",
            "Cost optimization",
            "Brand promotion"
        ]
    };


    const getBiweeklyRange = (date = new Date()) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        let start, end;
        if (day <= 15) {
            start = new Date(year, month, 1);
            end = new Date(year, month, 15);
        } else {
            start = new Date(year, month, 16);
            end = new Date(year, month, daysInMonth);
        }

        return {
            start: start.toISOString().split("T")[0], // Format as YYYY-MM-DD
            end: end.toISOString().split("T")[0],
        };
    };

    // Initialize dates on mount
    const fetchRatings = () => {
        setLoader(true)
        axiosInstance
            .put(`/user/getAllAssociateRatings?startDate=${startDate}&endDate=${endDate}`)
            .then((res) => {
                setPerfData(res.data)
                console.log(res.data)
                setLoader(false)
            })
            .catch((err) => {
                console.error("API Error:", err);
                setLoader(false)
            });
    };

    // Set biweekly range on mount
    useEffect(() => {
        const { start, end } = getBiweeklyRange();
        setStartDate(start);
        setEndDate(end);
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchRatings();
        }
    }, [startDate, endDate]);


    // Handle biweekly navigation
    const handleStartDateChange = (e, type) => {
        if (type === "icon") {
            const newStartDate =
                moment(startDate).format("DD").toString() === "01"
                    ? moment(startDate)
                        .subtract(1, "month")
                        .startOf("month")
                        .add(15, "days")
                        .format("YYYY-MM-DD")
                    : moment(startDate).startOf("month").format("YYYY-MM-DD");
            const newEndDate =
                moment(startDate).format("DD").toString() === "01"
                    ? moment(startDate)
                        .subtract(1, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD")
                    : moment(startDate)
                        .startOf("month")
                        .add(14, "day")
                        .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        } else if (type === "icon") {
            const newStartDate = moment(startDate)
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            const newEndDate = moment(endDate)
                .subtract(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        } else {
            const value = e.target.value;
            setStartDate(value);
            // setEndDateError("");
            if (value && endDate && value > endDate) {
                // setStartDateError("Start date cannot be after end date");
            } else {
                // setStartDateError("");
            }
        }
    };

    const handleEndDateChange = (e, type) => {
        if (type === "icon") {
            const newStartDate =
                moment(startDate).format("DD").toString() === "01"
                    ? moment(startDate).add(15, "days").format("YYYY-MM-DD")
                    : moment(startDate)
                        .add(1, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD");
            const newEndDate =
                moment(startDate).format("DD").toString() === "01"
                    ? moment(startDate).endOf("month").format("YYYY-MM-DD")
                    : moment(startDate)
                        .add(1, "month")
                        .startOf("month")
                        .add(14, "days")
                        .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        } else if (type === "icon") {
            const newStartDate = moment(startDate)
                .add(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            const newEndDate = moment(endDate)
                .add(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        } else {
            const value = e.target.value;
            setEndDate(value);
            // setStartDateError("");
            if (value && startDate && value < startDate) {
                // setEndDateError("End date cannot be before start date");
            } else {
                // setEndDateError("");
            }
        }
    };

    // Handling category change
    const handleKeyChange = (event) => {
        const selectedKey = event.target.value;
        setSelectionKey(selectedKey);
        setSelectedValue('ALL'); // Reset to 'ALL' when category changes
    };

    // Handling subcategory change
    const handleValueChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };

    // Handling start date change
    // const handleStartDateChange = (event) => {
    //     setStartDate(event.target.value);
    //     console.log("Start Date:", event.target.value);
    // };

    // // Handling end date change
    // const handleEndDateChange = (event) => {
    //     setEndDate(event.target.value);
    //     console.log("End Date:", event.target.value);
    // };

    // const tableData2 = e.data;
    const tableData2 = perfData;

    // Function to parse ratings objects
    function parseRatingsObj(dataList) {
        return dataList.map(item => {
            // Check if ratingsObj exists and is a string, then parse it
            if (item.ratingsObj && typeof item.ratingsObj === 'string') {
                item.ratingsObj = JSON.parse(item.ratingsObj);  // Convert string to array
            }
            return item;  // Return the modified object
        });
    }

    const tableData = parseRatingsObj(tableData2); // Parsed data

    const getFilteredData = () => {
        let filteredData = tableData.map(item => {
            let totalScore = 0;

            if (selectedValue === 'ALL') {
                totalScore = item.ratingsObj.reduce((sum, rating) => {
                    if (rating.rowIndex === Object.keys(data).indexOf(selectionKey)) {
                        return sum + rating.rating;
                    }
                    return sum;
                }, 0);
            } else {
                const filteredRatings = item.ratingsObj.filter(rating =>
                    rating.rowIndex === Object.keys(data).indexOf(selectionKey) &&
                    data[selectionKey][rating.subIndex] === selectedValue
                );

                totalScore = filteredRatings.length > 0 ? filteredRatings[0]?.rating : 0;
            }

            return {
                associateId: item.associateId,
                name: item.fullname,
                project: item.projectTitles || "N/A",
                projectManager: item.projectManagerName || "N/A",
                score: (totalScore)*180,
            };
        });

        // Sort data
        filteredData = sortOrder === 'asc'
            ? filteredData.sort((a, b) => a.score - b.score)
            : filteredData.sort((a, b) => b.score - a.score);

        return filteredData.slice(0, 5);
    };

    const columnDefs = [
        { headerName: 'Ast ID', field: 'associateId' },
        { headerName: 'Associate Name', field: 'name' },
        { headerName: 'Project', field: 'project' },
        { headerName: 'Project Manager', field: 'projectManager' },
        { headerName: 'Uk Score', field: 'score' },
    ];

    const gridOptions = {
        pagination: false,
        domLayout: 'normal',
        rowSelection: 'single',
        defaultColDef: {
            resizable: true,
            width: 252,
        },
    };

    return (
        <Card style={{ maxHeight: "479px" }}>
            <div style={{ margin: "20px", marginBottom: "0px" }}>
                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                    <div>
                        <Typography style={{
                            display: "inline-block",
                            marginRight: "5px"
                        }}>Category: </Typography>
                        <Select
                            labelId="category-select-label"
                            id="category-select"
                            value={selectionKey}
                            onChange={handleKeyChange}
                            style={{ font: "small-caption", fontFamily: "Lucida Grande", height: "35px" }}
                        >
                            {Object.keys(data).map((key) => (
                                <MenuItem key={key} value={key} style={{ font: "small-caption" }}>
                                    {key}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div>
                        <Typography style={{
                            display: "inline-block",
                            marginRight: "5px"
                        }}>Parameters: </Typography>
                        <Select
                            id="value-select"
                            value={selectedValue}
                            onChange={handleValueChange}
                            style={{ font: "small-caption", fontFamily: "Lucida Grande", height: "35px" }}

                        >
                            <MenuItem value="ALL" style={{ font: "small-caption" }}>ALL</MenuItem>
                            {data[selectionKey]?.map((value, index) => (
                                <MenuItem key={index} value={value} style={{ font: "small-caption" }}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <Tooltip title="Click">
                            <IconButton onClick={(e) => handleStartDateChange(e, "icon")}>
                                <ArrowBackIosIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>

                        <TextField
                            type="date"
                            value={startDate}
                            label="Start Date"
                            size="small"
                            onChange={handleStartDateChange}
                            InputLabelProps={{
                                style: { font: "small-caption", fontFamily: "Lucida Grande" },
                                shrink: true,
                            }}
                            InputProps={{
                                style: { font: "small-caption", fontFamily: "Lucida Grande" },
                            }}
                        />

                        <TextField
                            type="date"
                            label="End Date"
                            size="small"
                            value={endDate}
                            onChange={handleEndDateChange}
                            InputLabelProps={{
                                style: { font: "small-caption", fontFamily: "Lucida Grande" },
                                shrink: true,
                            }}
                            InputProps={{
                                style: { font: "small-caption", fontFamily: "Lucida Grande" },
                            }}
                        />

                        <Tooltip title="Click">
                            <IconButton onClick={(e) => handleEndDateChange(e, "icon")}>
                                <ArrowForwardIosIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>

                </div>

                {/* Ag-Grid Table */}
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginTop: "20px" }}>
                    {
                        loader ? (<Grid sx={{ textAlignLast: "center" }} item xs={12}>
                            <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                                <CircularProgress />
                            </Box>
                        </Grid>) : (<AgGridReact
                            gridOptions={gridOptions}
                            columnDefs={columnDefs}
                            rowData={getFilteredData()}
                            domLayout="normal"
                            defaultColDef={{
                                filter: true,
                                headerClass: "custom-header",
                            }}
                            onGridReady={(params) => params.api.sizeColumnsToFit()}
                        />)
                    }


                </div>
            </div>
        </Card>
    );
};

export default PerfDashboard;
