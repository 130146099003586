import { Button, Grid, Link, Tooltip, Typography } from "@mui/material";
import saveAs from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { utils, write } from "xlsx";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import GenericTable from "../../edvenswa.emportal.common/pages/GenericTable";
import "../css/ratings.css";
import GenerateReport from "./GenerateReport";
import PastRatingsCustomCell from "./PastRatingsCustomCell";
import { RowNodeBlock } from "ag-grid-community";
import FileSaver from "file-saver";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator";
import PerfDashboard from "./PerfDashboard";

const ReportComponent = (props) => {
  const { onError, setLoad, user, appType, setAppType, state, setState } =
    props;
  const navigate = useNavigate();
  const [users, setusers] = useState([]);
  const [filteredusers, setFilteredusers] = useState([]);
  const [username, setusername] = useState("");
  const [userTasks, setUserTasks] = useState([]);
  const [tenantId, setTenantId] = useState(user.tenantId);
  const [project, setProject] = useState("All");
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("ACTIVE");
  const pageSize = 5;
  const totalPages = 0;
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const role = user.roles[0];
  const type = props.type ? props.type : "Report";
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [holiday, setHoliday] = useState([]);
  const [ratingsMap, setRatingsMap] = useState([]);
  const location = useLocation();
  const [viewDashboard, setViewDashboard] = useState(false);
  const [perfData, setPerfData] = useState([])

  // const handleStartDateChange = (e, type) => {
  //   if (type === "icon") {
  //     const newStartDate = moment(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
  //     const newEndDate = moment(endDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
  //     setStartDate(newStartDate);
  //     setEndDate(newEndDate);
  //   }
  //   else {
  //     const value = e.target.value;
  //     setStartDate(value);
  //     setEndDateError("");
  //     if (value && endDate && value > endDate) {
  //       setStartDateError("Start date cannot be after end date");
  //     } else {
  //       setStartDateError("");
  //     }
  //   }
  // };

  // const handleEndDateChange = (e, type) => {
  //   if (type === "icon") {
  //     const newStartDate = moment(startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD');
  //     const newEndDate = moment(endDate).add(1, 'month').endOf('month').format('YYYY-MM-DD');
  //     setStartDate(newStartDate);
  //     setEndDate(newEndDate);
  //   }
  //   else {
  //     const value = e.target.value;
  //     setEndDate(value);
  //     setStartDateError("");
  //     if (value && startDate && value < startDate) {
  //       setEndDateError("End date cannot be before start date");
  //     } else {
  //       setEndDateError("");
  //     }
  //   }
  // };

  let totalDates = [];
  for (
    let i = moment(startDate);
    moment(i).isSameOrBefore(endDate);
    i = moment(i).add(1, "day")
  ) {
    if (
      moment(i).format("dddd").toLowerCase() !== "saturday" &&
      moment(i).format("dddd").toLowerCase() !== "sunday"
    ) {
      totalDates.push(i);
    }
  }

  const countLeaves = (filteredElements) => {
    let count = 0;
    filteredElements.forEach((element) => {
      if (element.taskCategory === "Leave") {
        const start = new Date(
          element.startDate[0],
          element.startDate[1],
          element.startDate[2]
        );
        const end = new Date(
          element.endDate[0],
          element.endDate[1],
          element.endDate[2]
        );
        count += (end - start) / (1000 * 60 * 60 * 24) + 1;
      }
    });
    return count;
  };

  const dates = {
    startDate: startDate,
    endDate: endDate,
  };

  useEffect(() => {
    axiosInstance
      .put("/holiday/duration", dates)
      .then((res) => {
        setHoliday(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [startDate, endDate]);

  useEffect(() => {
    axiosInstance
      .put(`/user/getAllAssociateRatings?startDate=${dates.startDate}&endDate=${dates.endDate}`)
      .then((res) => {
        setPerfData(res.data)
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [startDate, endDate]);


  const countFestival = holiday.length;

  const CustomNameComponent = (row) => {
    return (
      <Link
        sx={{ cursor: "pointer" }}
        underline="hover"
        onClick={() => {
          setLoad(true);

          if (type === "Appraisals") {
            const data = {
              user: { username: row.data.username },
              startDate: props?.startDate,
              endDate: props?.endDate,
            };
            axiosInstance
              .put("/user/getRatingsByUser", data)
              .then((res) => {
                if (res.data.length > 0) {
                  navigate("/home/ratings/UserScreenAppraisal", {
                    state: {
                      userRatings: res.data,
                      type: type,
                      username: row.data.username,
                      startDate: props?.startDate,
                      endDate: props?.endDate,
                    },
                  });
                  setLoad(false);
                } else {
                  const error = {
                    message: `No Ratings Found for the ${row.data.firstname} ${row.data.lastname}`,
                  };
                  setLoad(false);
                  onError(error);
                }
              })
              .catch((err) => onError(err));
          } else {
            const data = {
              startDate: startDate,
              endDate: endDate,
              associate: row.data.associateId,
            };
            axiosInstance
              .put("/task/getbyuserbystarddateandenddate", data)
              .then((res) => {
                if (res.data.length > 0) {
                  navigate("/home/reports/download", {
                    state: {
                      userTasks: res.data,
                      fullname: `${row.data.firstname} ${row.data.lastname}`,
                      project:
                        project === "All" ? row.data.projectShow : project,
                      startDate: startDate,
                      endDate: endDate,
                    },
                  });
                  setLoad(false);
                } else {
                  const error = {
                    message: `No Tasks Found for the ${row.data.firstname} ${row.data.lastname} for the selected Dates`,
                  };
                  setLoad(false);
                  onError(error);
                }
              })
              .catch((err) => onError(err));
          }
        }}
      >
        {row.data.firstname} {row.data.lastname}
      </Link>
    );
  };
  const CustomRatingComponent = (props) => {
    return (
      <Grid sx={{ display: "flex", mt: 1, alignItems: "center" }}>
        <Typography>{props.data.ratings.toFixed(2)}</Typography>
      </Grid>
    );
  };

  const CustomButtonComponent = (props) => {
    return (
      <>
        <Tooltip title="click to Download PDF">
          <Button
            size="small"
            InputLabelProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
              shrink: true,
            }}
            onClick={() => handleDownloadPDF(props)}
            disabled={startDateError || endDateError}
            InputProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
            }}
            variant="outlined"
            style={{ color: "white", marginRight: "10px" }}
          >
            PDF
          </Button>
        </Tooltip>
        <Tooltip title="click to Download Excel">
          <Button
            size="small"
            InputLabelProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
              shrink: true,
            }}
            onClick={() => handleDownloadReport(props)}
            disabled={startDateError || endDateError}
            InputProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
            }}
            variant="outlined"
            style={{ color: "white" }}
          >
            Excel
          </Button>
        </Tooltip>
      </>
    );
  };

  const PastRatingsCell = (row) => {
    const ratings = ratingsMap?.[row?.data?.associateId] ?? [];
    return (
      <PastRatingsCustomCell
        user={user}
        project={row?.data?.projectTitles}
        ratings={ratings}
        astId={row?.data?.associateId}
      />
    );
  };

  const ScoreCell = (row) => {
    const score =
      row.data.score > 0
        ? row.data.score
        : ratingsMap?.[row?.data?.associateId]?.score ?? 0; // Default to 0 if score is not available
    const rawScore = (score / 63) * 5;
    const truncatedScore = Math.floor(rawScore * 10000) / 10000;
    const formattedScore = truncatedScore > 0 ? truncatedScore : 0;
    return (
      <Grid
        sx={{
          display: "flex",
          mt: 1,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Typography sx={{ textAlign: "right", width: "100%" }}>
          {isNaN(formattedScore) ? "--" : Math.floor(formattedScore * 180)}
        </Typography>
      </Grid>
    );
  };

  const CustomPMCell = (row) => {
    const projectManager = row?.data?.projectManager?.fullname;
    return (
      <Grid sx={{ display: "flex", alignItems: "center" }}>
        <div>{projectManager}</div>
      </Grid>
    );
  };

  const getRatingAge = (row) => {
    if (ratingsMap?.[row?.data?.associateId]?.score === 0) {
      return <div style={{ color: "gray" }}>No rating available</div>;
    }
    const ratingDate =
      ratingsMap?.[row?.data?.associateId]?.dates?.[0]?.endDate;
    if (!ratingDate) {
      return <div style={{ color: "gray" }}>No rating available</div>;
    }
    const rating = new Date(ratingDate);
    const currentDate = new Date();
    const timeDifference = currentDate - rating;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const weeksDifference = Math.floor(daysDifference / 7);
    const color = weeksDifference > 2 ? "gray" : "black";
    if (daysDifference < 7) {
      return (
        <div style={{ color }}>
          {`${daysDifference} day${daysDifference > 1 ? "s" : ""} ago`}
        </div>
      );
    } else {
      return (
        <div style={{ color }}>
          {`${weeksDifference} week${weeksDifference > 1 ? "s" : ""} ago`}
        </div>
      );
    }
  };

  const getRatingAgeCount = (row) => {
    if (ratingsMap?.[row?.data?.associateId]?.score === 0) {
      return 0;
    }
    const ratingDate =
      ratingsMap?.[row?.data?.associateId]?.dates?.[0]?.endDate;
    const rating = new Date(ratingDate);
    const currentDate = new Date();
    const timeDifference = currentDate - rating;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const weeksDifference = Math.floor(daysDifference / 7);
    return daysDifference;
  };

  const colDefs = [
    {
      field: "associateId",
      headerName: "Ast ID",
      image: "logo",
      cellStyle: { textAlign: "right" },
      // type: "text",
      flex: 1,
    },
    {
      field: "fullname",
      headerName: "Associate Name",
      cellRenderer: CustomNameComponent,
      image: "logo",
      // type: "text",
      flex: 1,
      valueGetter: (params) => {
        return `${params.data.firstname} ${params.data.lastname}`;
      },
    },
  ];
  let projectColumn;
  if (role === "Admin") {
    projectColumn = {
      field: type === "Appraisals" ? "projectTitles" : "projects",
      headerName: "Project",
      // type: "text",
      flex: 1,
    };
  } else {
    projectColumn = {
      field: "projectShow",
      headerName: "Project",
      // type: "text",
      width: 300,
    };
  }
  colDefs.splice(2, 0, projectColumn);

  colDefs.push(
    props.type === "Appraisals"
      ? appType === "Users"
        ? {
          headerName: "Project Manager",
          cellRenderer: CustomPMCell,
          flex: 1,
        }
        : {}
      : {},
    props.type === "Appraisals"
      ? appType === "Users"
        ? {
          cellRenderer: ScoreCell,
          headerName: "UK Score",
          valueGetter: (params) => {
            return params.data.score > 0
              ? params.data.score
              : ratingsMap?.[params?.data?.associateId]?.score ?? 0;
          },
          flex: 1,
          cellStyle: { textAlign: "right" },
          headerClass: "align-right",
        }
        : {}
      : {
        field: "Download",
        headerName: "Download",
        // type: "text",
        cellRenderer: CustomButtonComponent,
        width: 300,
      },
    props.type === "Appraisals"
      ? appType === "Users"
        ? {
          headerName: "PERF Ratings",
          cellRenderer: PastRatingsCell,
          flex: 1,
        }
        : {}
      : {},
    props.type === "Appraisals"
      ? appType === "Users"
        ? {
          headerName: "Time Since Rated",
          cellRenderer: getRatingAge,
          valueGetter: (params) => {
            return getRatingAgeCount(params);
          },
          flex: 1,
        }
        : {}
      : {}
  );
  const displayAdd = true;

  useEffect(() => {
    setLoading(true);
    props?.setState({});
    if (role === "Project Manager") {
      if (searchTerm.length === 0 && users.length === 0) {
        let arr = [];
        user.projects.map((data) =>
          data.users.map((user) => {
            let userObj = { ...user };
            userObj = { ...userObj, projectShow: data.title };
            arr.push(userObj);
          })
        );
        setusers(arr.filter((data) => data.associateId !== user.associateId));
        setFilteredusers(
          arr.filter((data) => data.associateId !== user.associateId)
        );
        setLoading(false);
      } else {
        const searchedUsers = users.filter(
          (data) =>
            data["firstname"].toLowerCase().includes(searchTerm) ||
            data["lastname"].toLowerCase().includes(searchTerm) ||
            data["fullname"].toLowerCase().includes(searchTerm) ||
            `${data.associateId}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        );
        setFilteredusers(searchedUsers);
        setLoading(false);
      }
    } else {
      if (type === "Appraisals" && searchTerm.length === 0) {
        if (appType === "Users") {
          axiosInstance
            .post(
              `/user/getAllassociatesforRatings?sortBy=${sortBy}&tenantId=${tenantId}`,
              { startDate: props.startDate, endDate: props.endDate }
            )
            .then((res) => {
              let data;
              if (
                location?.state?.projectManager &&
                Object.keys(location?.state?.projectManager).length > 0
              ) {
                data = res?.data.filter(
                  (ast) =>
                    ast?.projectManager?.username ===
                    location?.state?.projectManager?.username
                );
              } else {
                data = res?.data;
              }
              const astIds = data.map((user) => user?.associateId);
              if (astIds) {
                axiosInstance
                  .put(
                    `/user/get/past/ratings?endDate=${props.endDate}`,
                    astIds
                  )
                  .then((res) => setRatingsMap(res?.data))
                  .catch((err) => console.log(err));
              }
              const users = data.filter((user) => user.role !== "Admin");
              setusers(users);
              setFilteredusers(users);
              setLoading(false);
            });
        } else {
          axiosInstance
            .post("/user/ratingsNotSubmitted", {
              startDate: props.startDate,
              endDate: props.endDate,
            })
            .then((res) => {
              setusers(res.data);
              setFilteredusers(res.data);
              props.setFilteredElements(res.data);
              setLoading(false);
            });
        }
      } else if (searchTerm.length === 0) {
        axiosInstance
          .get(`/user/associatesLists?sortBy=${sortBy}&tenantId=${tenantId}`)
          .then((res) => {
            setusers(res.data.filter((user) => user.role !== "Admin"));
            setFilteredusers(res.data.filter((user) => user.role !== "Admin"));
            setLoading(false);
          });
      } else {
        const searchedUsers = users.filter(
          (data) =>
            data["firstname"].toLowerCase().includes(searchTerm) ||
            data["lastname"].toLowerCase().includes(searchTerm) ||
            // (data["fullname"].toLowerCase().includes(searchTerm)) ||
            `${data.associateId}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        );
        setFilteredusers(searchedUsers);
        setLoading(false);
      }
    }

    if (username.length > 0) {
      const data = {
        username: username,
      };
      axiosInstance.post("/task/getAllByUser", data).then((res) => {
        if (res.data.length > 0) {
          setUserTasks(res.data);
        } else {
          const error = {
            message: "No Tasks Found",
          };
          props.onError(error);
        }
      });
    }
  }, [
    page,
    pageSize,
    searchTerm,
    state?.searchTerm, // Adding dynamic state.searchTerm here
    username,
    tenantId,
    sortBy,
    props.startDate,
    props.endDate,
    appType,
  ]);

  const handleDownloadPendingRatingsReport = () => {
    axiosInstance
      .post(
        "/user/pending-biweekly-ratings/download",
        {
          startDate: props.startDate,
          endDate: props.endDate,
        },
        {
          responseType: "blob", // Ensure the response is treated as a binary Blob
        }
      )
      .then((response) => {
        // Extract filename from response headers, if available
        const contentDisposition = response.headers["content-disposition"];
        let filename = "pending_biweekly_ratings.xlsx";
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?([^"]+)"?/);
          if (match) {
            filename = match[1];
          }
        }

        // Create a Blob from the response data and trigger download
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        FileSaver.saveAs(blob, filename); // Use FileSaver for better cross-browser support
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  };

  const handleDownloadPDF = (props) => {
    let filteredElements = [];
    let leaveCount = 0;

    const data = {
      startDate: startDate,
      endDate: endDate,
      associate: props.data.associateId,
    };
    axiosInstance
      .put("/task/getbyuserbystarddateandenddate", data)
      .then((res) => {
        if (res.data.length > 0) {
          if (role === "Admin") {
            filteredElements = res.data;
            leaveCount = countLeaves(filteredElements);
            goToPDF(
              filteredElements,
              props.data.fullname,
              leaveCount,
              countFestival
            );
          } else {
            if (project === "All") {
              filteredElements = res.data.filter(
                (data) => data.project === props.data.projectShow
              );
              leaveCount = countLeaves(filteredElements);
              goToPDF(
                filteredElements,
                props.data.fullname,
                leaveCount,
                countFestival
              );
            } else {
              filteredElements = res.data.filter(
                (data) => data.project === project
              );
              leaveCount = countLeaves(filteredElements);
              goToPDF(
                filteredElements,
                props.data.fullname,
                leaveCount,
                countFestival
              );
            }
          }
        } else {
          const error = {
            message: `No Tasks Found for the ${props.data.firstname} ${props.data.lastname} for the selected Dates`,
          };
          setLoad(false);
          onError(error);
        }
      })
      .catch((err) => onError(err));
  };

  const goToPDF = (filteredElements, fullname, leaveCount) => {
    let estimatedHourssum = 0;
    let workedHourssum = 0;
    let percentageUsedsum = 0;

    filteredElements.map((task) => {
      estimatedHourssum += task.estimatedHours;
      workedHourssum += task.workedHours;
      percentageUsedsum += task.percentageUsed;
    });
    workedHourssum -= leaveCount * 8 * 3600;

    if (filteredElements.length > 0) {
      const pdfWidth = 210;
      const pdfHeight = 250;
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });
      const logoUrl =
        "https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180";
      const centerX = pdf.internal.pageSize.width / 2;
      const imageWidth = 20;
      const imageHeight = 20;
      const imageY = 2;
      pdf.addImage(
        logoUrl,
        centerX - imageWidth / 2,
        imageY,
        imageWidth,
        imageHeight
      );

      const formattedData = filteredElements.reduce((acc, task) => {
        const taskData =
          JSON.parse(task?.task)?.workedDayHours !== null &&
          Object.keys(JSON.parse(task?.task).workedDayHours).map((date) => {
            const seconds = JSON.parse(task.task).workedDayHours[date];
            return {
              Project: task?.project ? task?.project : "",
              "Task Name": task.taskName ? task.taskName : "",
              "Task Start Date": task.startDate
                ? moment(task.startDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
                : " ",
              "Task End Date": task.endDate
                ? moment(task.endDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
                : " ",
              "Task Category": task.category ? task.category : "",
              "Date Logged": moment(date, "DD-MM-YYYY").format("DD-MMM-YYYY"),
              "Task Hours Logged": moment()
                .startOf("day")
                .seconds(seconds)
                .format("HH:mm"),
            };
          });

        return acc.concat(taskData);
      }, []);

      const cleanedFormattedData = formattedData.filter((row) =>
        Object.values(row).some((val) => val)
      );

      const sortedData = cleanedFormattedData.sort((a, b) => {
        const dateA = moment(a["Date Logged"], "DD-MMM-YYYY");
        const dateB = moment(b["Date Logged"], "DD-MMM-YYYY");
        return dateA - dateB;
      });

      const header = [
        "Project",
        "Task Name",
        "Task Start Date",
        "Task End Date",
        "Task Category",
        "Date Logged",
        "Task Hours Logged",
      ];

      const userData = sortedData
        .filter(
          (val) =>
            moment(val["Date Logged"], "DD-MMM-YYYY").isSameOrAfter(
              moment(startDate)
            ) &&
            moment(val["Date Logged"], "DD-MMM-YYYY").isSameOrBefore(
              moment(endDate)
            ),
          startDate,
          endDate
        )
        .map((val) => header.map((header) => val[header]));

      // Calculate total logged hours
      let totalLoggedSeconds = sortedData.reduce((acc, val) => {
        if (
          moment(val["Date Logged"], "DD-MMM-YYYY").isSameOrAfter(
            moment(startDate)
          ) &&
          moment(val["Date Logged"], "DD-MMM-YYYY").isSameOrBefore(
            moment(endDate)
          )
        ) {
          const hoursLogged = moment
            .duration(val["Task Hours Logged"])
            .asSeconds();
          return acc + hoursLogged;
        }
        return acc;
      }, 0);

      // Calculate the total leave hours in seconds
      const leaveHoursLogged = sortedData
        .filter((val) => val["Task Category"] === "Leave")
        .map((val) => moment.duration(val["Task Hours Logged"]).asSeconds());

      const totalLeaveSeconds = leaveHoursLogged.reduce(
        (acc, seconds) => acc + seconds,
        0
      );
      const totalLeaveHours = `${Math.floor(
        totalLeaveSeconds / 3600
      )}:${Math.floor((totalLeaveSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0")}`;

      //remove totalLeaveSeconds from totalLoggedSeconds
      totalLoggedSeconds = Math.max(0, totalLoggedSeconds - totalLeaveSeconds);

      //converting toatlLoggedSeconds into Hours
      const totalLoggedHours = `${Math.floor(
        totalLoggedSeconds / 3600
      )}:${Math.floor((totalLoggedSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0")}`;

      const tableStartY = imageY + imageHeight + 20; //44

      // Average Utilization
      const totalWorkingHoursInSeconds = totalDates.length * 8 * 3600;
      const utilizationPercentage =
        (totalLoggedSeconds / totalWorkingHoursInSeconds) * 100;
      const formattedPercentage = `${utilizationPercentage.toFixed(2)} %`;

      pdf.autoTable({
        head: [header],
        body: userData,
        margin: { left: 8, right: 8, bottom: 10 },
        startY: (startDate && endDate ? 58 : 58) + 10,
        rowPageBreak: "avoid",
        headStyles: {
          fillColor: "#008080",
          textColor: "#ffffff",
          fontStyle: "bold",
        },

        didDrawPage: function (data) {
          if (data.pageNumber === 1) {
            pdf.setFont("Times New Roman, Times, serif");
            pdf.text(
              `Dear Admin, Following is the report of ${fullname}.`,
              12,
              tableStartY - 6
            );
            pdf.setFontSize(13);
            pdf.text(
              `For the time period of ${moment(startDate).format(
                "DD-MMM-yyyy"
              )} to ${moment(endDate).format("DD-MMM-yyyy")} `,
              12,
              46
            );
            // pdf.text((`Total Working Hours- ${Math.floor(totalDates.length * 8)}:00,  Total Task Hours Logged- ${totalLoggedHours}, Total Leave Hours- ${totalLeaveHours}, Holiday Hours-${Math.floor(countFestival*8)}:00,  Avg.Utilization- ${formattedPercentage}`), 12, startDate && endDate ? 54 : 46);
            const fullText =
              `Total Working Hours- ${Math.floor(totalDates.length * 8)}:00, ` +
              `Total Task Hours Logged- ${totalLoggedHours}, ` +
              `Total Leave Hours- ${totalLeaveHours}, ` +
              `Holiday Hours- ${Math.floor(countFestival * 8)}:00, ` +
              `Avg. Utilization- ${formattedPercentage}`;

            const splitText = pdf.splitTextToSize(fullText, 180);
            pdf.text(splitText, 12, startDate && endDate ? 54 : 46);
          }
        },
        didParseCell: function (data) {
          data.cell.styles.lineWidth = 0.1;
          data.cell.styles.lineColor = [0, 0, 0];
        },
      });

      pdf.save(`${fullname}.pdf`);
    }
  };

  const handleDownloadReport = (props) => {
    let filteredElements = [];

    const data = {
      startDate: startDate,
      endDate: endDate,
      associate: props.data.associateId,
    };
    axiosInstance
      .put("/task/getbyuserbystarddateandenddate", data)
      .then((res) => {
        if (res.data.length > 0) {
          if (role === "Admin") {
            filteredElements = res.data;
            goToExcel(filteredElements, props.data.fullname);
          } else {
            if (project === "All") {
              filteredElements = res.data.filter(
                (data) => data.project === props.data.projectShow
              );
              goToExcel(filteredElements, props.data.fullname);
            } else {
              filteredElements = res.data.filter(
                (data) => data.project === project
              );
              goToExcel(filteredElements, props.data.fullname);
            }
          }
        } else {
          const error = {
            message: `No Tasks Found for the ${props.data.firstname} ${props.data.lastname} for the selected Dates`,
          };
          setLoad(false);
          onError(error);
        }
      })
      .catch((err) => onError(err));
  };

  const handelDashboardView = () => {
    setViewDashboard(!viewDashboard)
  }

  const [selectionKey, setSelectionKey] = useState('Capability'); // Default selection key
  const [selectedValue, setSelectedValue] = useState('ALL'); // Default selected value is "ALL"

  const data = {
    Capability: [
      "ALL",
      "Execution efficiency",
      "Quality of output",
      "Accountability",
      "Ownership"
    ],
    Creativity: [
      "ALL",
      "Originality",
      "Alignment",
      "Complexity and Effort"
    ],
    Collaboration: [
      "ALL",
      "Team Contribution",
      "Adaptability and Flexibility",
      "Relationship Building"
    ],
    Compliance: [
      "ALL",
      "Awareness",
      "Adherence",
      "Accuracy and Integrity of records"
    ],
    CustomerFocus: [
      "ALL",
      "Convenience",
      "Value Creation",
      "Cost optimization",
      "Brand promotion"
    ]
  };

  const handleKeyChange = (event) => {
    const selectedKey = event.target.value;
    setSelectionKey(selectedKey);
    setSelectedValue('ALL'); // Reset second selection to "ALL" when first selection changes
    console.log('First Selection Changed:', selectedKey); // Log the change
    console.log('Second Selection (Value) Reset to:', 'ALL'); // Log the second selection reset
  };

  const handleValueChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    console.log('First Selection (Category):', selectionKey); // Log the first selection
    console.log('Second Selection Changed (Value):', value); // Log the second selection change
  };

  // Get the values based on the selected key
  const selectionValues = data[selectionKey];


  const goToExcel = (filteredElements, fullname) => {
    if (filteredElements.length > 0) {
      const fileName = `${fullname} Report.xlsx`;

      const sortedData = filteredElements.sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateA - dateB;
      });

      const formattedData = sortedData.reduce((acc, task) => {
        const taskData =
          JSON.parse(task?.task)?.workedDayHours !== null &&
          Object.keys(JSON.parse(task?.task).workedDayHours).map((date) => {
            const seconds = JSON.parse(task.task).workedDayHours[date];
            return {
              Project: task.project ? task.project : "",
              "Task Name": task.taskName ? task.taskName : "",
              "Start Date": task.startDate
                ? moment(task.startDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
                : " ",
              "End Date": task.endDate
                ? moment(task.endDate, "YYYY,MM,DD").format("DD-MMM-yyyy")
                : " ",
              "Task Category": task.category ? task.category : "",
              "Date Logged": moment(date, "DD-MM-YYYY").format("DD-MMM-YYYY"),
              "Task Hours Logged": moment()
                .startOf("day")
                .seconds(seconds)
                .format("HH:mm"),
              "Calculate Hours": seconds / 3600,
            };
          });
        return acc.concat(taskData);
      }, []);

      const cleanedFormattedData = formattedData.filter((row) =>
        Object.values(row).some((val) => val)
      );

      // Sort formattedData by Associate and Date Logged
      const sortedFormattedData = cleanedFormattedData.sort((a, b) => {
        const dateA = moment(a["Date Logged"], "DD-MMM-yyyy").toDate();
        const dateB = moment(b["Date Logged"], "DD-MMM-yyyy").toDate();
        return dateA - dateB;
      });

      const header = [
        "Project",
        "Task Name",
        "Start Date",
        "End Date",
        "Task Category",
        "Date Logged",
        "Task Hours Logged",
        "Calculate Hours",
      ];

      const userData = sortedFormattedData.filter(
        (val) =>
          moment(val["Date Logged"], "DD-MMM-YYYY").isSameOrAfter(
            moment(startDate)
          ) &&
          moment(val["Date Logged"], "DD-MMM-YYYY").isSameOrBefore(
            moment(endDate)
          ),
        startDate,
        endDate
      );

      // Calculate total logged hours
      let totalLoggedSeconds = sortedFormattedData.reduce((acc, val) => {
        if (
          moment(val["Date Logged"], "DD-MMM-YYYY").isSameOrAfter(
            moment(startDate)
          ) &&
          moment(val["Date Logged"], "DD-MMM-YYYY").isSameOrBefore(
            moment(endDate)
          )
        ) {
          const [hours, minutes] = val["Task Hours Logged"]
            .split(":")
            .map(Number);
          const hoursLogged = hours * 3600 + minutes * 60;
          return acc + hoursLogged;
        }
        return acc;
      }, 0);

      /// Calculate the total leave hours in seconds
      const leaveHoursLogged = sortedFormattedData
        .filter((val) => val["Task Category"] === "Leave")
        .map((val) => moment.duration(val["Task Hours Logged"]).asSeconds());

      const totalLeaveSeconds = leaveHoursLogged.reduce(
        (acc, seconds) => acc + seconds,
        0
      );
      const totalLeaveHours = `${Math.floor(
        totalLeaveSeconds / 3600
      )}:${Math.floor((totalLeaveSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0")}`;

      // Convert total seconds back to HH:mm format
      const totalLoggedHours = `${Math.floor(
        totalLoggedSeconds / 3600
      )}:${Math.floor((totalLoggedSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0")}`;

      // Convert totalLoggedHours from HH:mm to seconds
      const [loggedHours, loggedMinutes] = totalLoggedHours
        .split(":")
        .map(Number);
      const totalLoggedSecondsWithoutLeave =
        loggedHours * 3600 + loggedMinutes * 60 - totalLeaveSeconds;

      // Convert the resulting seconds back into HH:mm format
      const totalLoggedHoursWithoutLeaves = `${Math.floor(
        totalLoggedSecondsWithoutLeave / 3600
      )}:${Math.floor((totalLoggedSecondsWithoutLeave % 3600) / 60)
        .toString()
        .padStart(2, "0")}`;

      const totalWorkingHours = `${totalDates.length * 8}:00`;

      //Insert an empty row to create a gap before the summary row
      userData.push({
        Project: "",
        "Task Name": "",
        "Start Date": "",
        "End Date": "",
        "Task Category": "",
        "Date Logged": "",
        "Task Hours Logged": "",
        "Calculate Hours": "",
      });

      // Add total logged hours as three separate summary rows
      const summaryRow1 = {
        Project: "",
        "Task Name": "",
        "Start Date": "",
        "End Date": "",
        "Task Category": "",
        "Date Logged": "",
        "Task Hours Logged": `Total Working Hours: ${totalWorkingHours}`,
        "Calculate Hours": "",
      };

      const summaryRow2 = {
        Project: "",
        "Task Name": "",
        "Start Date": "",
        "End Date": "",
        "Task Category": "",
        "Date Logged": "",
        "Task Hours Logged": `Total Logged Hours: ${totalLoggedHoursWithoutLeaves}`,
        "Calculate Hours": "",
      };

      const summaryRow3 = {
        Project: "",
        "Task Name": "",
        "Start Date": "",
        "End Date": "",
        "Task Category": "",
        "Date Logged": "",
        "Task Hours Logged": `Total Leave Hours: ${totalLeaveHours}`,
        "Calculate Hours": "",
      };
      const summaryRow4 = {
        Project: "",
        "Task Name": "",
        "Start Date": "",
        "End Date": "",
        "Task Category": "",
        "Date Logged": "",
        "Task Hours Logged": `Total Holiday Hours: ${Math.floor(
          countFestival * 8
        )}:00`,
        "Calculate Hours": "",
      };

      userData.push(summaryRow1, summaryRow2, summaryRow3, summaryRow4);

      const worksheet = utils.json_to_sheet(userData, { header });
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer = write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName);
    }
  };

  return (
    <>
      {
        type === "Appraisals" ? (<Grid item xs={12} sx={{ alignItems: "center", justifyContent: "end", display: "flex" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            style={{
              marginBottom: 10
            }}
            onClick={() => handelDashboardView()}

          >
            {viewDashboard ? "Appraisals Dashboard" : "PERF Dashboard"}
          </Button>
        </Grid>) : (null)
      }
      {
        viewDashboard ? (
          <PerfDashboard data={perfData} />

        ) : (<GenericTable
          type={type}
          setTenantId={setTenantId}
          Companies={props.Companies}
          setUsers={setusers}
          colDefs={colDefs.filter((col) => Object.keys(col).length > 0)}
          elements={users}
          filteredElements={filteredusers}
          setFilteredElements={setFilteredusers}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          startDateError={
            type === "Appraisals" ? props.startDateError : startDateError
          }
          endDateError={type === "Appraisals" ? props.endDateError : endDateError}
          setStartDateError={
            type === "Appraisals" ? props.setStartDateError : setStartDateError
          }
          setEndDateError={
            type === "Appraisals" ? props.setEndDateError : setEndDateError
          }
          page={page}
          appType={appType}
          setAppType={setAppType}
          sortBy={type === "Appraisals" ? props.sortBy : sortBy}
          setSortBy={type === "Appraisals" ? props.setSortBy : setSortBy}
          setPage={setPage}
          setDateFilter={props.setDateFilter}
          setProject={setProject}
          totalPages={totalPages}
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
          errorMessage={errorMessage}
          startDate={type === "Appraisals" ? props.startDate : startDate}
          setStartDate={type === "Appraisals" ? props.setStartDate : setStartDate}
          endDate={type === "Appraisals" ? props.endDate : endDate}
          setEndDate={type === "Appraisals" ? props.setEndDate : setEndDate}
          handleDownloadReport={
            type === "Appraisals" ? props.handleDownloadReport : null
          }
          handleDownloadReportExcel={
            type === "Appraisals" ? props.handleDownloadReportExcel : null
          }
          handleDownloadPendingRatingsReport={
            type === "Appraisals" ? handleDownloadPendingRatingsReport : null
          }
          // handleStartDateChange={handleStartDateChange}
          // handleEndDateChange={handleEndDateChange}
          loading={loading}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          state={state}
          setState={setState}
          drawerContent={
            <GenerateReport
              tenantId={tenantId}
              onError={onError}
              type={type}
              user={user}
              setIsDrawerOpen={setIsDrawerOpen}
              setFilteredElements={setFilteredusers}
              setElements={setusers}
            />
          }
          displayAdd={props.type === "Appraisals" ? false : displayAdd}
          displayHeader={displayAdd}
        />)
      }
    </>
  );
};

export default ReportComponent;
