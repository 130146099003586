import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Rating,
  Button,
  Container,
  Grid,
  Divider,
  FormControl,
  FormHelperText,
  Paper,
  Alert,
  Snackbar,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import StarIcon from "@mui/icons-material/Star";
import BadgeIcon from "@mui/icons-material/Badge";
import WorkIcon from "@mui/icons-material/Work";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { Business } from "@mui/icons-material";

const EmployeeFeedbackForm = () => {
  const [employee, setEmployee] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (id) {
      axiosInstance
        .get(`/user?astId=${id}`)
        .then((response) => {
          setEmployee(response.data || null);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [id]);

  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [company, setCompany] = useState("");
  const [isPublic, setIsPublic] = useState(false);

  // Form validation
  const validateForm = () => {
    const newErrors = {};

    if (!clientName.trim()) {
      newErrors.clientName = "Name is required";
    } else if (clientName.trim().length < 3) {
      newErrors.clientName = "Name must be at least 3 characters long";
    }

    if (!clientEmail.trim()) {
      newErrors.clientEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(clientEmail)) {
      newErrors.clientEmail = "Email is invalid";
    }

    if (rating === 0) newErrors.rating = "Please provide a rating";

    if (!feedback.trim()) {
      newErrors.feedback = "Feedback is required";
    } else if (!/\b\w{2,}\b/.test(feedback)) {
      newErrors.feedback = "Feedback must contain at least one word";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // In a real app, you would send this data to your backend
      const data = {
        associateId: employee.associateId,
        clientName,
        clientEmail,
        rating,
        feedback,
        company,
        isPublic,
      };
      axiosInstance
        .post("/user/client-feedback", data)
        .then((response) => {
          setClientName("");
          setClientEmail("");
          setRating(0);
          setFeedback("");
          setCompany("");
          setIsPublic(true);
          setSubmitted(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // Handle snackbar close
  const handleCloseSnackbar = () => {
    setSubmitted(false);
  };

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Associate Feedback Form
        </Typography>

        <Divider sx={{ my: 2 }} />

        {loading ? (
          <CircularProgress />
        ) : loading && employee === null ? (
          <Alert severity="error" sx={{ textAlign: "center" }}>
            Employee with ID {id} not found.
          </Alert>
        ) : (
          <>
            <Card variant="outlined" sx={{ mb: 4, bgcolor: "#f5f5f5" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Associate Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <BadgeIcon sx={{ mr: 1, color: "primary.main" }} />
                      <Typography variant="body1">
                        <strong>Associate ID:</strong> {employee?.associateId}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PersonIcon sx={{ mr: 1, color: "primary.main" }} />
                      <Typography variant="body1">
                        <strong>Full Name:</strong> {employee?.fullname}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <WorkIcon sx={{ mr: 1, color: "primary.main" }} />
                      <Typography variant="body1">
                        <strong>Designation:</strong> {employee?.designation}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AccountTreeIcon sx={{ mr: 1, color: "primary.main" }} />
                      <Typography variant="body1">
                        <strong>Level:</strong> {employee?.level}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Feedback Form */}
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" gutterBottom>
              Feedback Provider
                            </Typography>

              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors.clientName}>
                    <TextField
                      label="Your Name"
                      variant="outlined"
                      value={clientName}
                      onChange={(e) => setClientName(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <PersonIcon sx={{ mr: 1, color: "text.secondary" }} />
                        ),
                      }}
                      error={!!errors.clientName}
                    />
                    {errors.clientName && (
                      <FormHelperText>{errors.clientName}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors.clientEmail}>
                    <TextField
                      label="Your Email"
                      variant="outlined"
                      type="email"
                      value={clientEmail}
                      onChange={(e) => setClientEmail(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <EmailIcon sx={{ mr: 1, color: "text.secondary" }} />
                        ),
                      }}
                      error={!!errors.clientEmail}
                    />
                    {errors.clientEmail && (
                      <FormHelperText>{errors.clientEmail}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Company (Optional)"
                    variant="outlined"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <Business sx={{ mr: 1, color: "text.secondary" }} />
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>
                Associate Rating & Feedback
              </Typography>

              <Box sx={{ mb: 3 }}>
                <Typography component="legend" gutterBottom>
                  Rate the associate's performance
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Rating
                    name="employee-rating"
                    value={rating}
                    onChange={(e, newValue) => setRating(newValue)}
                    precision={0.5}
                    size="large"
                    icon={<StarIcon fontSize="inherit" />}
                    emptyIcon={<StarIcon fontSize="inherit" />}
                  />
                  <Typography
                    variant="body2"
                    sx={{ ml: 2, color: "text.secondary" }}
                  >
                    {rating > 0
                      ? `${rating} out of 5 stars`
                      : "No rating provided"}
                  </Typography>
                </Box>
                {errors.rating && (
                  <FormHelperText error>{errors.rating}</FormHelperText>
                )}
              </Box>

              <FormControl fullWidth error={!!errors.feedback} sx={{ mb: 4 }}>
                <TextField
                  label="Your Feedback"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  placeholder="Please share your feedback about the employee's performance, communication, and any other relevant aspects..."
                  error={!!errors.feedback}
                />
                {errors.feedback && (
                  <FormHelperText>{errors.feedback}</FormHelperText>
                )}
              </FormControl>

              <FormControlLabel
                control={
                  <Switch
                    checked={isPublic}
                    onChange={(e) => setIsPublic(e.target.checked)}
                    color="primary"
                  />
                }
                label={isPublic ? "Public Feedback" : "Private Feedback"}
                sx={{ mb: 3 }}
              />

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{ px: 4, py: 1.5 }}
                >
                  Submit Feedback
                </Button>
              </Box>
            </form>
          </>
        )}
      </Paper>

      <Snackbar
        open={submitted}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Feedback submitted successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EmployeeFeedbackForm;
